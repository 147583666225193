import { Box, makeStyles } from '@material-ui/core';
import { OutlinedInputWithLabel } from '@novozymes-digital/components';
import React, { ReactElement, useCallback, useMemo } from 'react';

const useStyles = makeStyles(() => ({
  label: { marginBottom: '1rem', fontSize: '0.875rem', margin: 0, lineHeight: 1 },
  inputWrapperTime: {
    width: '13.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface TimeFieldProps {
  label: string;
  timeInSec: number;
  setTime: (newTime: number) => void;
  hasError?: boolean;
  disabled?: boolean;
}

const TimeField = ({ label, timeInSec, setTime, disabled, hasError }: TimeFieldProps): ReactElement => {
  const classes = useStyles();

  const timeMinutes = useMemo(() => {
    return Math.floor(timeInSec / 60);
  }, [timeInSec]);

  const timeSec = useMemo(() => {
    return timeInSec - timeMinutes * 60;
  }, [timeInSec, timeMinutes]);

  const handleTimeInputChange = useCallback(
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let updateTotal;
      const value = parseInt(event.target.value);

      if (field === 'minutes') {
        updateTotal = value * 60 + timeSec;
      } else {
        updateTotal = timeMinutes * 60 + value;
      }
      setTime(isNaN(updateTotal) ? 0 : updateTotal);
    },
    [setTime, timeMinutes, timeSec]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.label}>{label}</Box>
      <Box className={classes.inputWrapperTime}>
        <Box marginRight="0.5rem">
          <OutlinedInputWithLabel
            adornment="min"
            type="number"
            value={timeMinutes}
            placeholder="20"
            handleChange={handleTimeInputChange('minutes')}
            error={hasError}
            errorMessage="Invalid Input"
            disabled={disabled}
          />
        </Box>
        <Box marginRight="0.5rem">
          <OutlinedInputWithLabel
            adornment="s"
            type="number"
            value={timeSec}
            placeholder="0"
            handleChange={handleTimeInputChange('seconds')}
            error={hasError}
            errorMessage="Invalid Input"
            disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TimeField;
