import React, { ReactElement } from 'react';
import { makeStyles, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';

const useStyles = makeStyles({
  menuList: {
    padding: '0.125rem 1rem',
  },
  menuItem: {
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    borderRadius: '0.5rem',
    height: '1.5rem',
    minHeight: '1.5rem',
    margin: '0.5rem auto',
    '&:hover': {
      backgroundColor: colors.softGreen,
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperWrapper: {
    borderRadius: '1rem',
    width: '14.5rem',
    marginTop: '0.5rem',
    padding: '0.5rem 0',
  },
});

export interface PopupMenuItem {
  name: string;
  onClick: () => void;
}

type PopperPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface PopupMenuProps {
  anchorRef: HTMLElement | null;
  popupOpen: boolean;
  setAnchorEl: (value: HTMLElement | null) => void;
  items: Array<PopupMenuItem>;
  placement?: PopperPlacement;
}

export default function PopupMenu(props: PopupMenuProps): ReactElement {
  const classes = useStyles();

  const { anchorRef, popupOpen, setAnchorEl, items, placement } = props;

  return (
    <Popper
      open={popupOpen}
      role={undefined}
      placement={placement ? placement : 'bottom'}
      transition
      disablePortal
      anchorEl={anchorRef}
      className={classes.popper}
    >
      {({ TransitionProps }): ReactElement => (
        <Grow {...TransitionProps}>
          <Paper className={classes.popperWrapper}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <MenuList className={classes.menuList}>
                {items.map((item) => (
                  <MenuItem
                    key={item.name}
                    className={classes.menuItem}
                    onClick={() => {
                      item.onClick();
                      setAnchorEl(null);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
