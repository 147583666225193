import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { makeStyles, TableRow, TableHead, TableContainer, TableBody, Table, Box, IconButton } from '@material-ui/core';

import PopupMenu from '../PopupMenu';
import { colors, DropDown, DropUp, More } from '@novozymes-digital/components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '../toaster';
import _ from 'underscore';
import CreateTemplateExperimentModal from './CreateTemplateExperimentModal';
import ConfirmationModal from '../ConfirmationModal';
import { deleteTemplate, getTemplates, Template } from '../../utils/templateUtils';
import { StyledTableCell, StyledTableRow } from '../Table';
import { userState } from '../../store/authState';
import { useRecoilValue } from 'recoil';
import filterOnProps from '../../utils/filterOnProps';
import FilterTextField from '../FilterTextField';
import { getUserInitials } from '../../utils/getUserInitials';

interface RowProps {
  template: Template;
  handleOpenCreateExperimentModal: (id: number | null) => void;
  handleDeleteTemplateClick: (id: number | null) => void;
  username: string;
}

const Row = (props: RowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const popupMenuOpen = Boolean(anchorEl);
  const { template, handleOpenCreateExperimentModal, handleDeleteTemplateClick, username } = props;
  const navigate = useNavigate();

  const popupItems = [
    {
      name: 'Create experiment',
      onClick: () => handleOpenCreateExperimentModal(template.id),
    },
  ];

  if (username === template.created_by) {
    popupItems.push({
      name: 'Delete',
      onClick: () => handleDeleteTemplateClick(template.id),
    });
  }

  const handlePopupClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleRowClick = () => {
    navigate(`/template/${template.id}`);
  };

  return (
    <>
      <StyledTableRow key={template.id}>
        <StyledTableCell component="th" scope="row" onClick={handleRowClick}>
          {template.name}
        </StyledTableCell>
        <StyledTableCell onClick={handleRowClick}>{template.id}</StyledTableCell>
        <StyledTableCell onClick={handleRowClick}>{moment(template.valid_from).format('YYYY-MM-DD')}</StyledTableCell>
        <StyledTableCell onClick={handleRowClick}>{getUserInitials(template.created_by)}</StyledTableCell>
        <StyledTableCell align="right" nonHover>
          <IconButton color="inherit" component="span" onClick={handlePopupClick}>
            <More />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <PopupMenu
        anchorRef={anchorEl}
        popupOpen={popupMenuOpen}
        setAnchorEl={setAnchorEl}
        items={popupItems}
        placement="left"
      />
    </>
  );
};

const useStyles = makeStyles({
  table: {
    boxShadow: 'none',
  },
  header: {
    borderBottom: `2px solid ${colors.dataGrey}`,
  },
  optionsButton: {
    cursor: 'pointer',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

type SortField = 'valid_from' | 'name' | 'created_by' | 'id';

interface GetTemplatesResponse {
  data: Template[];
}

export default function CustomizedTables(): ReactElement {
  const [sortField, setSortField] = useState<SortField>('valid_from');
  const [sortOrder, setSortOrder] = useState<string>('DESC');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [allTemplates, setAllTemplates] = useState<Template[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [createExperimentModalOpen, setCreateExperimentModalOpen] = useState<boolean>(false);
  const [chosenTemplateId, setChosenTemplateId] = useState<number | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const user = useRecoilValue(userState);

  const [searchString, setSearchString] = useState('');

  const propsToSearch: Array<Partial<keyof Template>> = useMemo(() => ['name', 'id', 'created_by', 'valid_from'], []);

  const filteredTemplates = useMemo(() => {
    return templates.filter((template) => {
      return filterOnProps<Template>(propsToSearch, template, searchString);
    });
  }, [propsToSearch, templates, searchString]);

  const classes = useStyles();

  const handleTableSorting = (field: SortField) => {
    if (sortField === field) {
      sortOrder === 'ASC' ? setSortOrder('DESC') : setSortOrder('ASC');
    } else {
      setSortField(field);
      setSortOrder('DESC');
    }
  };

  const getSortIcon = (field: string) => {
    if (sortField === field) {
      if (sortOrder === 'ASC') {
        return <DropUp />;
      }
      return <DropDown />;
    }
    return <Box width="24px" />;
  };

  useEffect(() => {
    getTemplates().then((res: GetTemplatesResponse) => {
      setAllTemplates(res.data);
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let updatedTemplates = _.sortBy(allTemplates, (e: any) => {
      if (e[sortField] && typeof e[sortField] === 'string') {
        return e[sortField].toLowerCase();
      }
      return e[sortField];
    });

    if (sortOrder === 'DESC') updatedTemplates = updatedTemplates.reverse();

    setTemplates(updatedTemplates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder, allTemplates]);

  const handleCloseError = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
    setErrorMessage('');
  };
  const handleCloseSuccess = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
    setSuccessMessage('');
  };

  const handleOpenCreateExperimentModal = (id: number | null) => {
    setCreateExperimentModalOpen(true);
    setChosenTemplateId(id);
  };

  const handleDeleteTemplateClick = (id: number | null) => {
    setDeleteModalOpen(true);
    setChosenTemplateId(id);
  };

  const confirmDeleteTemplate = () => {
    deleteTemplate(chosenTemplateId)
      .then(() => {
        setShowSuccess(true);
        setSuccessMessage('Successfully deleted template');
        setDeleteModalOpen(false);
        getTemplates().then((res: GetTemplatesResponse) => {
          setTemplates(res.data);
        });
      })
      .catch(() => {
        setDeleteModalOpen(false);
        setShowError(true);
        setErrorMessage('Failed to delete template');
      });
  };

  return (
    <Box>
      <Box>
        <FilterTextField onSearchStringUpdate={setSearchString} placeholder="Filter templates" />
      </Box>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.header}>
            <TableRow>
              <StyledTableCell onClick={() => handleTableSorting('name')}>
                <Box className={classes.tableHeadContent}>Title {getSortIcon('name')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('id')}>
                <Box className={classes.tableHeadContent}>Id {getSortIcon('id')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('valid_from')}>
                <Box className={classes.tableHeadContent}>Date created {getSortIcon('valid_from')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('created_by')}>
                <Box className={classes.tableHeadContent}>Created by {getSortIcon('created_by')}</Box>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTemplates &&
              filteredTemplates.map((template: Template) => (
                <Row
                  key={template.id}
                  handleOpenCreateExperimentModal={handleOpenCreateExperimentModal}
                  handleDeleteTemplateClick={handleDeleteTemplateClick}
                  template={template}
                  username={user.attributes.email.toLowerCase()}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={showError} autoHideDuration={5000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={showSuccess} autoHideDuration={5000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <CreateTemplateExperimentModal
        show={createExperimentModalOpen}
        onClose={() => setCreateExperimentModalOpen(false)}
        templateId={chosenTemplateId}
      />
      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        message="Are you sure you want to delete this template?"
        action={confirmDeleteTemplate}
      />
    </Box>
  );
}
