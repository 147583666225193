import { AxiosResponse } from 'axios';
import ApiUtils from '../services/apiUtils';
import { Experiment } from './experimentUtils';
import { Template } from './templateUtils';

export const getTimingCalculationParameters = (): Promise<AxiosResponse> => ApiUtils.get('/atom_parameters');

export interface TimingCalculationParameter {
  field_name: string;
  field_value: string;
  field_unit: string;
  id: number;
}

export const getFormattedTimingCalculationValue = (timingCalculationValue: number | null): string => {
  if (timingCalculationValue) {
    const h = Math.floor(timingCalculationValue / 3600);
    const m = Math.floor((timingCalculationValue % 3600) / 60);

    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes') : '';
    return hDisplay + mDisplay;
  }
  return '0';
};

interface TimingUserSettings {
  number_washes: number;
  soaking_time: number;
  wash_time: number;
  rinse_time: number;
  detergent_components: number;
  inactivation: number;
  temperature: number;
}

interface TimingAtomSettings {
  beaker_count: number;
  add_detergent: number;
  clear_detergent: number;
  add_enzyme_single: number;
  add_enzyme_double: number;
  add_swatch: number;
  discard_cup: number;
  get_suction: number;
  suction: number;
  wash_empty: number;
  rinse_fill: number;
  rinse_empty: number;
  inactive_fill: number;
  inactive_empty: number;
  component_fill: number;
  total_rinse_time: number;
  total_rinse_volume: number;
}

const getBaseCalculation = (
  userSettings: TimingUserSettings,
  atomParams: TimingAtomSettings,
  checkedENZ1: boolean,
  checkedENZ2: boolean
) => {
  let numberOfEnzymes = 0;
  if (checkedENZ1 && checkedENZ2) numberOfEnzymes = atomParams['add_enzyme_double'];
  else if ((checkedENZ1 && !checkedENZ2) || (!checkedENZ1 && checkedENZ2))
    numberOfEnzymes = atomParams['add_enzyme_single'];

  const prepTime = atomParams['add_detergent'] + numberOfEnzymes + atomParams['add_swatch'];

  // OLD Total Wash with rinse_cycles
  // const totalWash =
  //   userSettings['soaking_time'] +
  //   userSettings['wash_time'] +
  //   atomParams['wash_empty'] +
  //   (userSettings['rinse_time'] + atomParams['rinse_fill']) * userSettings['rinse_cycles'] +
  //   atomParams['rinse_empty'] * (userSettings['rinse_cycles'] - 1);

  const totalWash =
    userSettings['soaking_time'] +
    userSettings['wash_time'] +
    atomParams['wash_empty'] +
    atomParams['total_rinse_time'];

  const cycleTime = totalWash + prepTime + atomParams['suction'];

  const mixTime = userSettings['detergent_components'] * atomParams['component_fill'];

  const mixCycle = mixTime + atomParams['clear_detergent'];

  const inactivation = atomParams['inactive_fill'] + userSettings['inactivation'] + atomParams['inactive_empty'];

  return {
    numberOfEnzymes,
    prepTime,
    totalWash,
    cycleTime,
    mixTime,
    mixCycle,
    inactivation,
  };
};

export const getContinuousTimingCalculation = (
  userSettings: TimingUserSettings,
  atomParams: TimingAtomSettings,
  checkedENZ1: boolean,
  checkedENZ2: boolean
): number => {
  if (userSettings['number_washes'] <= 0) return 0;

  const baseValues = getBaseCalculation(userSettings, atomParams, checkedENZ1, checkedENZ2);

  const robotActive =
    baseValues.prepTime + atomParams['get_suction'] + atomParams['suction'] + atomParams['discard_cup'];

  const perBeakerAction = Math.max(baseValues.mixCycle, robotActive);

  let simultanious =
    Math.floor((baseValues.totalWash - atomParams['discard_cup'] - atomParams['get_suction']) / perBeakerAction) + 1;

  simultanious = Math.min(atomParams['beaker_count'], simultanious);

  let steps;
  if (userSettings['number_washes'] % simultanious === 0) {
    steps = simultanious - 1;
  } else {
    steps = (userSettings['number_washes'] % simultanious) - 1;
  }

  let runTime =
    baseValues.mixTime +
    baseValues.cycleTime * Math.ceil(userSettings['number_washes'] / simultanious) +
    steps * perBeakerAction +
    baseValues.inactivation;

  if (baseValues.cycleTime < simultanious * baseValues.mixCycle && userSettings['number_washes'] > simultanious) {
    runTime +=
      (simultanious * baseValues.mixCycle - baseValues.cycleTime) *
      Math.floor((userSettings['number_washes'] - 1) / simultanious);
  }

  if (simultanious >= atomParams['beaker_count']) {
    if (baseValues.mixCycle < robotActive) {
      runTime +=
        Math.floor(userSettings['number_washes'] / simultanious) *
        Math.ceil(baseValues.inactivation / robotActive) *
        perBeakerAction;
    } else {
      runTime += (baseValues.inactivation + 1) * Math.floor((userSettings['number_washes'] - 1) / simultanious);
    }
  }

  return runTime;
};

const getAtomTimingParams = async (): Promise<TimingAtomSettings> => {
  const response = await getTimingCalculationParameters();
  const formattedParameters: Partial<TimingAtomSettings> = {};
  response.data.forEach((p: TimingCalculationParameter) => {
    formattedParameters[p.field_name as keyof TimingAtomSettings] = parseInt(p.field_value);
  });

  return formattedParameters as TimingAtomSettings;
};

export const getTimingCalculationString = async (
  experiment: Experiment | Template
): Promise<{ estimatedTime: string; total_rinse_volume: number }> => {
  if (!experiment) {
    return { estimatedTime: '', total_rinse_volume: 0 };
  }
  const atomTimingCalculationParameters = await getAtomTimingParams();

  const userSettings = {
    number_washes: experiment.number_of_washes,
    soaking_time: experiment.soaking_time,
    wash_time: experiment.wash_time,
    rinse_time: atomTimingCalculationParameters.total_rinse_time,
    detergent_components: experiment.detergent_number,
    inactivation: 30,
    temperature: experiment.wash_water_temperature,
  };

  const checkedENZ1 = experiment.enzyme_plate_1_addition;
  const checkedENZ2 = experiment.enzyme_plate_2_addition;

  const timingCalculation = getContinuousTimingCalculation(
    userSettings,
    atomTimingCalculationParameters,
    checkedENZ1,
    checkedENZ2
  );

  return {
    estimatedTime: getFormattedTimingCalculationValue(timingCalculation),
    total_rinse_volume: atomTimingCalculationParameters.total_rinse_volume,
  };
};
