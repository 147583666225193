import React, { ReactElement } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

interface FilterTextFieldProps {
  onSearchStringUpdate: (searchString: string) => void;
  placeholder?: string;
}

const FilterTextField = ({ onSearchStringUpdate, placeholder }: FilterTextFieldProps): ReactElement => {
  return (
    <TextField
      inputProps={{
        style: {
          fontSize: '1rem',
        },
      }}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        onSearchStringUpdate(e.target.value);
      }}
    />
  );
};

export default FilterTextField;
