import React, { ReactElement } from 'react';
import { Modal, Box, Card, makeStyles } from '@material-ui/core';
import { Button, Close } from '@novozymes-digital/components';

const useStyles = makeStyles({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '1.5rem',
    width: '100%',
    maxWidth: 400,
    borderRadius: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  modalActions: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeModalButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface DeleteModalProps {
  deleteModalOpen: boolean;
  setDeleteModalOpen: (value: boolean) => void;
  handleDelete: () => void;
  message: string;
  primaryButtonName?: string;
}

const DeleteModal = (props: DeleteModalProps): ReactElement => {
  const classes = useStyles();
  const { deleteModalOpen, setDeleteModalOpen, handleDelete, message, primaryButtonName } = props;

  return (
    <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} className={classes.modalRoot}>
      <Card className={classes.modalBody}>
        <Box display="flex" justifyContent="center">
          <Box width="24px" />
          <Box fontSize="1rem" margin="3rem 0">
            {message}
          </Box>
          <Close className={classes.closeModalButton} onClick={() => setDeleteModalOpen(false)} />
        </Box>
        <Box className={classes.modalActions}>
          <Button onClick={handleDelete} small>
            {primaryButtonName ?? 'Delete'}
          </Button>
          <Button small type="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default DeleteModal;
