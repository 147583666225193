import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Close, OutlinedInputWithLabel } from '@novozymes-digital/components';
import { Box, makeStyles, Drawer } from '@material-ui/core';
import { NzTabs, NzTab } from '@novozymes-digital/dezymer-core';
import { BallastForm } from './BallastForm';
import { BallastMetaType } from '../../../../services/apiTypes';

const useStyles = makeStyles(() => ({
  drawerBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    minWidth: '60rem',
    gap: '16px',
  },
  drawerPaper: {
    maxWidth: '90%',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
  washLoadWrapper: {
    width: '15rem',
  },
}));

interface BallastDrawerProps {
  type: 'fixed' | 'variable';
  handleSaveData: (ballastData: BallastMetaType[], totalWashLoad: number) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
  ballastFields: BallastMetaType[];
  handleUpdateValue: (key: keyof BallastMetaType, newValue: string | number, selectedStainTab: number) => void;
}

export function BallastDrawer({
  handleUpdateValue,
  handleCloseModal,
  handleSaveData,
  isOpen,
  ballastFields,
  type,
}: BallastDrawerProps): ReactElement {
  const classes = useStyles();

  const [selectedStainTab, setSelectedStainTab] = useState(0);
  const [totalWashLoad, setTotalWashLoad] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTab = (event: any, newValue: any): void => {
    setSelectedStainTab(newValue);
  };

  const updateValue = useCallback(
    (key: keyof BallastMetaType, newValue: string | number) => {
      handleUpdateValue(key, newValue, selectedStainTab);
    },
    [handleUpdateValue, selectedStainTab]
  );

  const disableButton = useMemo(() => {
    if (!totalWashLoad && type === 'fixed') return true;
    return ballastFields.some((fields) => {
      if (
        !fields.ballast_name ||
        !fields.ballast_type ||
        !fields.ballast_batch ||
        (!fields.ballast_percentage_per_container && type === 'fixed')
      ) {
        return true;
      }
    });
  }, [ballastFields, totalWashLoad, type]);

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      classes={{ paper: classes.drawerPaper }}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        handleCloseModal();
      }}
    >
      <Box className={classes.drawerBody}>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="1rem" fontWeight="bold">
            {type === 'fixed' ? 'Fixed ballast types' : 'Variable ballast types'}
          </Box>
          <Close className={classes.closeButton} onClick={() => handleCloseModal()} />
        </Box>
        {type === 'fixed' && (
          <Box className={classes.washLoadWrapper}>
            <OutlinedInputWithLabel
              label={'Total Wash Load'}
              value={totalWashLoad}
              type="number"
              handleChange={(event) => {
                setTotalWashLoad(event.target.value);
              }}
              disabled={false}
            />
          </Box>
        )}

        <NzTabs value={selectedStainTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          {ballastFields.map((ballast, index) => (
            <NzTab key={index} label={ballast.ballast_name} value={index} />
          ))}
        </NzTabs>

        {ballastFields[selectedStainTab] && (
          <BallastForm fields={ballastFields[selectedStainTab]} handleUpdateValue={updateValue} type={type} />
        )}

        <Box className={classes.modalActions}>
          <Button
            small
            onClick={() => {
              handleCloseModal();
              handleSaveData(ballastFields, parseFloat(totalWashLoad));
            }}
            disabled={disableButton}
          >
            Save data
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
