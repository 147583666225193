import React, { ReactElement } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';
import { TabKey } from '../../store/tabState';

const useStyles = makeStyles(() => ({
  tab: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    lineHeight: '0.5rem',
    margin: '0.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    borderBottom: 'none',
  },
  activeTab: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    lineHeight: '0.5rem',
    margin: '0.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    borderBottom: `2px solid ${colors.primaryGreen}`,
  },
}));

interface TabsProps {
  activeTab: string;
  setActiveTab: (activeTab: TabKey) => void;
}

export default function ScrollableTabsButtonAuto(props: TabsProps): ReactElement {
  const classes = useStyles();

  const { activeTab, setActiveTab } = props;

  return (
    <Box mb={1}>
      <Grid container spacing={2}>
        <Grid item>
          <Box
            className={activeTab === 'experiments' ? classes.activeTab : classes.tab}
            onClick={() => setActiveTab('experiments')}
          >
            Experiments
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={activeTab === 'templates' ? classes.activeTab : classes.tab}
            onClick={() => setActiveTab('templates')}
          >
            Templates
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={activeTab === 'deleted' ? classes.activeTab : classes.tab}
            onClick={() => setActiveTab('deleted')}
          >
            Deleted Experiments
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
