import React, { ReactElement, ChangeEvent, useState } from 'react';
import { Modal, makeStyles, Box, InputBase, InputLabel, Typography } from '@material-ui/core';
import { Button, Close, colors, ToggleSwitch } from '@novozymes-digital/components';
import { Snackbar, Alert } from '../toaster';
import { shareExperiment, NewCollaborator, ChangeOwnerData, changeExperimentOwner } from '../../utils/sharingUtils';
import { AxiosError } from 'axios';
import { getUserList } from '../../store/authState';
import { useRecoilValue } from 'recoil';
import { useAutocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  saveModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    padding: theme.spacing(2, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none',
  },
  saveModalControls: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listbox: {
    width: 300,
    margin: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    overflow: 'auto',
    maxHeight: 200,
    padding: '0.5rem',
    backgroundColor: colors.bg1,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: colors.bg2,
      cursor: 'pointer',
    },
  },
  inputRoot: {
    backgroundColor: colors.bg1,
    padding: '0.625rem 0.75rem',
    borderRadius: '0.5rem',
    border: `1px solid ${colors.black47}`,
    fontSize: '0.875rem',
    height: '2.5rem',
    '&:hover': {
      border: `1px solid ${colors.black80}`,
    },
  },
  inputInput: {
    padding: 0,
  },
  inputFocused: {
    border: `1px solid ${colors.primaryGreen}`,
  },
  label: {
    display: 'inline-block',
    fontSize: '0.875rem',
    color: colors.black80,
    marginBottom: '1rem',
  },
  labelDisabled: {
    display: 'inline-block',
    fontSize: '0.875rem',
    color: colors.black47,
    marginBottom: '1rem',
  },
}));

export interface ShareWithModalProps {
  show: boolean;
  onClose(): void;
  experimentId: string;
  getCollaboratorList: (id: string) => void;
  setShowSharingPageSuccessMessage: () => void;
  setSharingPageSuccessMessage: (message: string) => void;
  currentUserUsername: string;
  isOwner: boolean;
}

const ShareWithModal = (props: ShareWithModalProps): ReactElement => {
  const [userToShareWith, setUserToShareWith] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const users = useRecoilValue(getUserList);
  const [shouldMakeOwner, setShouldMakeOwner] = useState(false);
  const {
    show,
    onClose,
    experimentId,
    getCollaboratorList,
    setShowSharingPageSuccessMessage,
    setSharingPageSuccessMessage,
    currentUserUsername,
    isOwner,
  } = props;

  const usersExcludingCurrent = (users || []).filter((username) => username !== currentUserUsername);
  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'use-autocomplete',
    options: usersExcludingCurrent,
    getOptionLabel: (option: string) => option,
  });

  const classes = useStyles();

  const shareAndMakeOwner = () => {
    const changeOwnerData: ChangeOwnerData = {
      old_owner: currentUserUsername,
      new_owner: userToShareWith,
      experiment_id: experimentId,
    };
    changeExperimentOwner(changeOwnerData)
      .then(() => {
        getCollaboratorList(experimentId);
        setShowSharingPageSuccessMessage();
        setSharingPageSuccessMessage(`Successfully made ${userToShareWith} owner of the experiment`);
        onClose();
      })
      .catch(() => {
        setShowError(true);
      });
  };

  const shareAsCollaborator = () => {
    const newCollaborator: NewCollaborator = {
      username: userToShareWith,
      access_level: 'collaborator',
      experiment_id: experimentId,
    };
    shareExperiment(newCollaborator)
      .then(() => {
        getCollaboratorList(experimentId);
        setShowSharingPageSuccessMessage();
        setSharingPageSuccessMessage(`Successfully shared experiment with ${userToShareWith}`);
        onClose();
      })
      .catch((e: AxiosError) => {
        if (e.response && e.response.data.detail === 'User does not exist in userpool') {
          setErrorMessage(
            'User does not exist. Make sure that the e-mail is correct and the user has access to Proton.'
          );
        } else {
          setErrorMessage(
            'Something went wrong when sharing the experiment. Please try again or contact the administrator.'
          );
        }
        setShowError(true);
      });
  };

  const handleShareSubmit = () => {
    if (userToShareWith.length) {
      if (isOwner && shouldMakeOwner) {
        shareAndMakeOwner();
      } else {
        shareAsCollaborator();
      }
    }
  };

  const handleCloseError = () => {
    setErrorMessage('');
    setShowError(false);
  };

  return (
    <>
      <Modal open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
        <Box className={classes.saveModal}>
          <Box className={classes.modalHeader}>
            <Close onClick={onClose} />
          </Box>
          <Box
            marginTop="0.5rem"
            {...getRootProps()}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              const { value } = event.target;
              setUserToShareWith(value);
            }}
          >
            <InputLabel className={classes.label}>Share with</InputLabel>
            <InputBase
              type="text"
              key="shareWith"
              {...getInputProps()}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
                focused: classes.inputFocused,
              }}
              fullWidth
              placeholder="Username"
            />
            {groupedOptions.length > 0 ? (
              <ul className={classes.listbox} {...getListboxProps()}>
                {groupedOptions.map((option: string, index: number) => (
                  <li {...getOptionProps({ option, index })} key={option}>
                    <Box
                      onClick={() => {
                        setUserToShareWith(option);
                      }}
                    >
                      {option}
                    </Box>
                  </li>
                ))}
              </ul>
            ) : null}
          </Box>
          <Box display="flex" justifyContent={isOwner ? 'space-between' : 'flex-end'} margin="2rem 0 1rem 0">
            {isOwner && (
              <ToggleSwitch
                label="Transfer ownership"
                labelPosition="right"
                small
                onChange={() => {
                  setShouldMakeOwner(!shouldMakeOwner);
                }}
                checked={shouldMakeOwner}
              />
            )}
            <Button type="primary" small onClick={handleShareSubmit} disabled={userToShareWith.length <= 1}>
              Share
            </Button>
          </Box>
          <Typography className={shouldMakeOwner ? classes.label : classes.labelDisabled}>
            There is only one owner per experiment. By activating this toggle button you will be transferring the
            ownership to the person assigned above.
          </Typography>
        </Box>
      </Modal>
      <Snackbar open={showError} autoHideDuration={5000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareWithModal;
