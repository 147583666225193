import axios from 'axios';
import { Auth } from 'aws-amplify';

const apiEndpoint = process.env.REACT_APP_BACKEND_API_URL;

const ApiUtils = {
  async get(url) {
    const requestConfig = await createRequestConfig();
    return axios.get(url, requestConfig);
  },

  async post(url, body) {
    const requestConfig = await createRequestConfig(url);
    return axios.post(url, body, requestConfig);
  },

  async put(url, body) {
    const requestConfig = await createRequestConfig();
    return axios.put(url, body, requestConfig);
  },

  async patch(url, body) {
    const requestConfig = await createRequestConfig();
    return axios.patch(url, body, requestConfig);
  },

  async delete(url, body) {
    const requestConfig = await createRequestConfig(body);
    requestConfig.data = body;
    return axios.delete(url, requestConfig);
  },
};

async function createRequestConfig(url) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return {
    baseURL: apiEndpoint,
    timeout: 500000,
    crossdomain: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
}

export default ApiUtils;
