import { PopupMenuItem } from '../PopupMenu';

interface GetExperimentMenuInput {
  isOwner: boolean;
  onDuplicateClick: () => void;
  onSharingClick: () => void;
  onCreateTemplateClick: () => void;
  onDeleteClick: () => void;
}

const getExperimentMenu = ({
  isOwner,
  onDuplicateClick,
  onSharingClick,
  onCreateTemplateClick,
  onDeleteClick,
}: GetExperimentMenuInput): Array<PopupMenuItem> => {
  const popupItems = [
    {
      name: 'Duplicate',
      onClick: onDuplicateClick,
    },
    {
      name: 'Sharing',
      onClick: onSharingClick,
    },
    {
      name: 'Create template',
      onClick: onCreateTemplateClick,
    },
  ];

  if (isOwner)
    popupItems.push({
      name: 'Delete',
      onClick: onDeleteClick,
    });

  return popupItems;
};

export default getExperimentMenu;
