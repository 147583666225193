import React, { ReactElement, ReactNode } from 'react';
import { Box, Toolbar, Typography, makeStyles } from '@material-ui/core';

import { colors, Faq } from '@novozymes-digital/components';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isAuthenticatedState, userState } from '../../store/authState';

const useStyles = makeStyles((theme) => ({
  userWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '300px',
  },
  userName: {
    fontSze: '0.875rem',
  },
  helpButton: {
    cursor: 'pointer',
    marginLeft: '0.7rem',
  },
  headerContainer: {
    padding: theme.spacing(0, 3),
    backgroundColor: colors.primaryBlue,
    color: colors.white95,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoText: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 4),
    width: '300px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logo: {
    width: '90px',
  },
  logoSubtitle: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    flex: 1,
  },
}));

interface HeaderProps {
  title?: ReactNode;
}

const Header = ({ title }: HeaderProps): ReactElement => {
  const user = useRecoilValue(userState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <>
      <Toolbar className={classes.headerContainer}>
        <Box id="home-logo" className={classes.logoText} onClick={() => navigate('/')}>
          <img src={'/nz_logo_white.svg'} alt="Novozymes logotype" className={classes.logo} />
          <Typography className={classes.logoSubtitle}>Proton</Typography>
        </Box>
        <Typography color="inherit" align="center" noWrap className={classes.headerTitle}>
          {title}
        </Typography>
        <Box className={classes.userWrapper}>
          <>
            {isAuthenticated ? (
              <>
                <Box className={classes.userName}>{user && user.attributes.name}</Box>
              </>
            ) : null}
            <span className={classes.helpButton} onClick={() => navigate('/help')}>
              <Faq />
            </span>
          </>
        </Box>
      </Toolbar>
    </>
  );
};

export default Header;
