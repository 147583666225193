import React, { useCallback, useEffect, useMemo, useState, ReactElement } from 'react';
import { Box } from '@material-ui/core';
import ExperimentsTable from './ExperimentsTable';
import { Experiment, getArchivedExperimentsByUser, restoreExperiment } from '../../utils/experimentUtils';
import DuplicateExperimentModal from './DuplicateExperimentModal';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/authState';
import FilterTextField from '../FilterTextField';
import filterOnProps from '../../utils/filterOnProps';
import { Snackbar, Alert } from '../toaster';
import { useNavigate } from 'react-router-dom';
import getDeletedExperimentMenu from './getDeletedExperimentMenu';

const DeletedExperimentsList = (): ReactElement => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [experimentForDuplication, setExperimentForDuplication] = useState<undefined | Experiment>(undefined);
  const [deletedExperiments, setDeletedExperiments] = useState<Experiment[]>([]);
  const [searchString, setSearchString] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const fetchArchivedExperiments = useCallback(() => {
    user.attributes.email &&
      getArchivedExperimentsByUser(user.attributes.email.toLowerCase()).then((res) => {
        const experiments: Experiment[] = res.data;
        setDeletedExperiments(experiments);
      });
  }, [user.attributes.email]);

  useEffect(() => {
    fetchArchivedExperiments();
  }, [fetchArchivedExperiments]);

  const propsToSearch: Array<Partial<keyof Experiment>> = useMemo(
    () => ['name', 'id', 'eln', 'created_by', 'modified_by', 'template', 'valid_from', 'last_modified'],
    []
  );

  const filteredExperiments = useMemo(() => {
    return deletedExperiments.filter((experiment) => {
      return filterOnProps<Experiment>(propsToSearch, experiment, searchString);
    });
  }, [propsToSearch, deletedExperiments, searchString]);

  const handleRestoreExperiment = (experiment: Experiment) => {
    if (experiment && experiment.id) {
      restoreExperiment(experiment.id)
        .then(() => {
          fetchArchivedExperiments();
          setSuccessMessage('Successfully restored experiment');
        })
        .catch(() => {
          setErrorMessage('Failed to restore experiment');
        });
    }
  };

  const getMenuItems = (experiment: Experiment) => {
    const onDuplicateClick = () => {
      setExperimentForDuplication(experiment);
    };

    const onRestoreClick = () => {
      handleRestoreExperiment(experiment);
    };

    return getDeletedExperimentMenu({ isOwner: experiment.access_level === 'owner', onDuplicateClick, onRestoreClick });
  };

  const handleExperimentClick = (experiment: Experiment) => {
    navigate(`/experiment/${experiment.id}`);
  };

  return (
    <Box>
      <FilterTextField onSearchStringUpdate={setSearchString} placeholder="Filter deleted experiments" />
      <ExperimentsTable
        experiments={filteredExperiments}
        getMenuItems={getMenuItems}
        onExperimentClick={handleExperimentClick}
      />
      <DuplicateExperimentModal
        originalExperiment={experimentForDuplication}
        onClose={() => setExperimentForDuplication(undefined)}
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage('');
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage('');
          }}
          severity="success"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeletedExperimentsList;
