import { atom, selector } from 'recoil';
import { getUsers } from '../utils/getUser';

export const isAuthenticatedState = atom({
  key: 'isauthenticatedstate',
  default: false,
});

interface UserState {
  username: string;
  attributes: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: number]: any;
    name: string;
    email: string;
    given_name: string;
    family_name: string;
    'custom:ad_groups': string[];
  };
}

export const userState = atom<UserState>({
  key: 'userState',
  default: {
    username: '',
    attributes: { name: '', email: '', given_name: '', family_name: '', ['custom:ad_groups']: [] },
  },
});

export const getUserList = selector({
  key: 'users',
  get: async () => {
    const response = await getUsers();
    return response.data.users;
  },
});
