const findStringInString = (mainString: string, searchString: string): boolean =>
  mainString.toLowerCase().includes(searchString.toLowerCase());

const filterOnProps = <T>(propsToSearch: Array<keyof T>, objectToSearch: T, searchString: string): boolean => {
  return (
    propsToSearch.findIndex((prop) => {
      const value = String(objectToSearch[prop]);
      return findStringInString(value, searchString);
    }) !== -1
  );
};

export default filterOnProps;
