import React, { ReactElement, ChangeEvent, useState } from 'react';
import { Modal, makeStyles, Box, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { OutlinedInputWithLabel, Button } from '@novozymes-digital/components';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '../toaster';
import { createTemplate } from '../../utils/templateUtils';
import { templateCreatedState } from '../../store/experimentState';
import { useSetRecoilState } from 'recoil';
import { tabState } from '../../store/tabState';

export interface NewTemplateModalProps {
  show: boolean;
  onClose(): void;
  experimentId: number | null;
}

const useStyles = makeStyles((theme) => ({
  saveModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  saveModalControls: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

const CreateTemplateModal = ({ show, onClose, experimentId }: NewTemplateModalProps): ReactElement => {
  const [showError, setShowError] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const setTemplateCreated = useSetRecoilState(templateCreatedState);
  const setActiveTab = useSetRecoilState(tabState);
  const classes = useStyles();

  const navigate = useNavigate();

  const handleTemplateSubmit = () => {
    const templateObj = {
      template_name: templateName,
      experiment_id: experimentId,
    };
    createTemplate(templateObj)
      .then(() => {
        setTemplateCreated(true);
        setActiveTab('templates');
        navigate(`/`);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <>
      <Modal open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
        <Box className={classes.saveModal}>
          <Box className={classes.modalHeader}>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box marginBottom="1.5rem" fontSize="0.875rem">
            <strong>Please be aware that all templates in Proton are public.</strong> All users will be able to view its
            content and use it to create new experiments.
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} justify="flex-start">
              <OutlinedInputWithLabel
                type="text"
                key="templateTitle"
                label="Enter template title"
                value={templateName}
                handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  const { value } = event.target;
                  setTemplateName(value);
                }}
              />
            </Grid>
            <Grid item xs={4} justify="flex-start"></Grid>
            <Grid item xs={5} justify="flex-start"></Grid>
            <Grid item xs={3} justify="center">
              <Button
                type="primary"
                small
                onClick={!templateName.length ? undefined : handleTemplateSubmit} // Until bug in component library is fixed
                disabled={!templateName.length}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error">
          Something went wrong creating your template. Please try again or contact the administrator.
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateTemplateModal;
