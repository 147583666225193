import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme/noTheme';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom';
import { NzFontFamily, NzThemeVariables } from '@novozymes-digital/dezymer-core';
import { CssBaseline } from '@material-ui/core';

// React 18 (Problem with style)
//import { createRoot } from 'react-dom/client';
// const container = document.getElementById('root');
// const root = createRoot(container as HTMLElement);
// root.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <RecoilRoot>
//         <App />
//       </RecoilRoot>
//     </ThemeProvider>
//   </React.StrictMode>
// );

/* Old way (React 17) */
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <NzThemeVariables />
      <NzFontFamily />
      <CssBaseline />
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
