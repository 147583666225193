import { pick } from 'lodash';
import { Wash } from '../../utils/experimentUtils';
import { MappedCSVData } from './CSVUpload';

const SortAndFilterWashes = (washes: Wash[]): MappedCSVData => {
  const columnsToInclude: Array<keyof Wash> = [];

  if (washes) {
    washes.forEach((wash: Wash) => {
      Object.keys(wash).forEach((key) => {
        const value = wash[key as keyof Wash];
        if (
          value !== null &&
          value !== undefined &&
          !['id', 'experiment_id', 'wash_order_id'].includes(key) &&
          !columnsToInclude.includes(key as keyof Wash)
        ) {
          columnsToInclude.push(key as keyof Wash);
        }
      });
    });
  } else {
    return [{}];
  }

  const washesData = washes.map((wash: Wash) => pick(wash, columnsToInclude.sort()));

  return washesData;
};

export default SortAndFilterWashes;
