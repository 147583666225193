import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Close } from '@novozymes-digital/components';
import { Box, makeStyles, Drawer } from '@material-ui/core';
import { NzTabs, NzTab } from '@novozymes-digital/dezymer-core';
import { StainForm } from './StainForm';
import { SwatchMetaType } from '../../../../services/apiTypes';

const useStyles = makeStyles(() => ({
  drawerBody: {
    padding: '24px',
    minWidth: '60rem',
  },
  drawerPaper: {
    maxWidth: '90%',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

interface StainDrawerProps {
  type: 'fixed' | 'variable';
  handleSaveData: (swatchData: SwatchMetaType[]) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
  swatchFields: SwatchMetaType[];
  handleUpdateValue: (
    key: keyof SwatchMetaType,
    newValue: string | number | string[],
    selectedStainTab: number
  ) => void;
}

export function StainDrawer({
  handleUpdateValue,
  handleCloseModal,
  handleSaveData,
  isOpen,
  swatchFields,
  type,
}: StainDrawerProps): ReactElement {
  const classes = useStyles();

  const [selectedStainTab, setSelectedStainTab] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTab = (event: any, newValue: any): void => {
    setSelectedStainTab(newValue);
  };

  const updateValue = useCallback(
    (key: keyof SwatchMetaType, newValue: string | number | string[]) => {
      handleUpdateValue(key, newValue, selectedStainTab);
    },
    [handleUpdateValue, selectedStainTab]
  );

  const disableButton = useMemo(() => {
    return swatchFields.some((fields) => {
      if (
        !fields.stain_types ||
        !fields.pieces_of_swatches_pr_container ||
        !fields.measurement_method ||
        fields.measurement_equipment.length <= 0 ||
        !fields.swatch_sides_measured
      ) {
        return true;
      }
    });
  }, [swatchFields]);

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      classes={{ paper: classes.drawerPaper }}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        handleCloseModal();
      }}
    >
      <Box className={classes.drawerBody}>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="1rem" fontWeight="bold" marginLeft="1.5rem">
            {type === 'fixed' ? 'Fixed stain types' : 'Variable stain types'}
          </Box>
          <Close className={classes.closeButton} onClick={() => handleCloseModal()} />
        </Box>

        <NzTabs value={selectedStainTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          {swatchFields.map((swatch, index) => (
            <NzTab key={index} label={swatch.stain_id} value={index} />
          ))}
        </NzTabs>

        {swatchFields[selectedStainTab] && (
          <StainForm fields={swatchFields[selectedStainTab]} handleUpdateValue={updateValue} type={type} />
        )}

        <Box className={classes.modalActions}>
          <Button
            small
            onClick={() => {
              handleCloseModal();
              handleSaveData(swatchFields);
            }}
            disabled={disableButton}
          >
            Save data
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
