import { Experiment } from './experimentUtils';
import { Template } from './templateUtils';

export const getCalculatedWaterConsumption = (
  experiment: Experiment | Template,
  total_rinse_volume: number
): number => {
  //OLD Water Consumption with rinse_cycles
  // return Math.floor(
  //   (experiment.number_of_washes * (parseInt(experiment.wash_volume.toString()) + rinse_cycles * 1000)) / 1000
  // );
  return Math.floor((experiment.number_of_washes * total_rinse_volume) / 1000);
};
