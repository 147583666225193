import React, { ReactElement, useState } from 'react';
import { Tooltip, makeStyles, Box } from '@material-ui/core';
import { colors, Faq } from '@novozymes-digital/components';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: colors.bg1,
    border: `1px solid ${colors.primaryGreen}`,
    borderRadius: '0.5rem',
    color: colors.black,
  },
  arrow: {
    color: colors.primaryGreen,
  },
}));

interface TooltipProps {
  title: NonNullable<React.ReactNode>;
}

const TooltipComponent = (props: TooltipProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  const { title } = props;

  return (
    <Tooltip
      placement="right"
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      title={title}
      disableFocusListener
    >
      <Box pl={1} display="flex" alignItems="center">
        <Faq fontSize="small" />
      </Box>
    </Tooltip>
  );
};

export default TooltipComponent;
