import { makeStyles, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Experiment } from '../utils/experimentUtils';

const useStyle = makeStyles(() => ({
  lastSavedTitleText: { fontSize: '1rem', fontStyle: 'italic' },
}));

const experimentStateTooltip: Record<string, string> = {
  draft: 'This experiment is in edit mode',
  sent: 'This experiment has been sent to A-TOM',
  executed: 'This experiment is running or has been already executed in A-TOM',
};

interface TitleProps {
  experiment?: Experiment;
  lastSaved?: string;
}

const Title = (props: TitleProps): ReactElement => {
  const classes = useStyle();
  const { experiment, lastSaved } = props;
  const title = experiment === undefined ? '' : experiment.experiment_state.toUpperCase();
  const tooltipTitle = experiment === undefined ? '' : experimentStateTooltip[experiment.experiment_state];

  return (
    <span>
      {experiment?.name} {' - '}
      <Tooltip title={tooltipTitle || ''}>
        <span>{title}</span>
      </Tooltip>
      {' - '} <span className={classes.lastSavedTitleText}>{lastSaved}</span>
    </span>
  );
};

export default Title;
