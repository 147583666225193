import React, { ReactElement } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';

const getBackgroundColor = (severity?: Severity): string => {
  switch (severity) {
    case 'error':
      return colors.softRed;
    case 'success':
      return '#F4F7D9';
    default:
      return colors.black;
  }
};

const getBorderColor = (severity?: Severity): string => {
  switch (severity) {
    case 'error':
      return colors.errorRed;
    case 'success':
      return colors.primaryGreenHover;
    default:
      return colors.black;
  }
};

const useStyles = makeStyles(() => ({
  alert: (props: AlertProps | null) => ({
    height: '2.25rem',
    textAlign: 'center',
    borderRadius: '0.25rem',
    padding: '0 1rem',
    color: colors.black80,
    backgroundColor: getBackgroundColor(props?.severity),
    border: `1px solid ${getBorderColor(props?.severity)}`,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.02)',
    '& .MuiAlert-message': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
  }),
  snackbar: {
    position: 'fixed',
    bottom: '5rem',
    height: '2.25rem',
    width: '100%',
  },
}));

type Severity = 'success' | 'info' | 'warning' | 'error';

export const Alert = (props: AlertProps): ReactElement => {
  const classes = useStyles(props);
  return <MuiAlert className={classes.alert} icon={false} variant="filled" {...props} />;
};

export const Snackbar = (props: SnackbarProps): ReactElement => {
  const classes = useStyles();
  return <MuiSnackbar className={classes.snackbar} {...props} />;
};
