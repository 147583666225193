import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Button, FolderAdd, Left } from '@novozymes-digital/components';
import { Box } from '@material-ui/core';
import ExperimentForm from '../components/Experiment/ExperimentForm';
import { FieldBoundary, getBoundaryValues } from '../services/getBoundaryValues';
import { Snackbar, Alert } from '../components/toaster';
import LoadingSpinner from '../components/LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import ButtonWithIcon from '../components/ButtonWithIcon';
import WashTable from '../components/Experiment/WashTable';
import SortAndFilterWashes from '../components/Experiment/SortAndFilterWashes';
import { deleteTemplate, getTemplate, Template } from '../utils/templateUtils';
import CreateTemplateExperimentModal from '../components/Template/CreateTemplateExperimentModal';
import DeleteModal from '../components/DeleteModal';
import SwatchTable from '../components/Experiment/StainTypes/variable/SwatchTable';
import BallastTable from '../components/Experiment/Ballast/variable/BallastTable';

const ExperimentComponent = (): ReactElement => {
  const [experimentErrorMessage, setExperimentErrorMessage] = useState<string>('');
  const [experimentSuccessMessage, setExperimentSuccessMessage] = useState<string>('');
  const [boundaryValues, setBoundaryValues] = useState<Record<string, FieldBoundary> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [templateId, setTemplateId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { experiment_id } = useParams();
  const [createExperimentModalOpen, setCreateExperimentModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [templateValues, setTemplateValues] = useState<Template>();

  useEffect(() => {
    if (experiment_id) {
      setTemplateId(Number(experiment_id));
      getBoundaryValues(experiment_id).then((boundariesObj) => {
        setBoundaryValues(boundariesObj);
      });
      handleFetchTemplate(experiment_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiment_id]);

  interface FetchTemplateResponse {
    data: Template;
  }

  const handleFetchTemplate = (id: string) => {
    setLoading(true);
    getTemplate(id).then((res: FetchTemplateResponse) => {
      res.data && setTemplateValues(res.data);
    });
    setLoading(false);
  };

  const handleCloseError = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setExperimentErrorMessage('');
  };

  const handleCloseSuccess = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setExperimentSuccessMessage('');
  };

  const handleBackClick = () => {
    navigate(`/`);
  };

  const handleDelete = () => {
    setDeleteModalOpen(false);
    deleteTemplate(templateId).then(() => {
      navigate(`/`);
    });
  };

  const subHeaderLeft = (
    <>
      <ButtonWithIcon id="btn-back-to-templates" title="Back to templates" icon={<Left />} onClick={handleBackClick} />
    </>
  );
  const subHeaderRight = (
    <>
      <ButtonWithIcon
        id="btn-create-experiment"
        title="Create Experiment"
        icon={<FolderAdd />}
        onClick={() => {
          setCreateExperimentModalOpen(true);
        }}
      />
      <ButtonWithIcon
        id="btn-delete-template"
        title="Delete template"
        icon={<FolderAdd />}
        onClick={() => {
          setDeleteModalOpen(true);
        }}
      />
    </>
  );

  const title = <>{templateValues?.name}</>;

  const mappedCSVData = useMemo(() => {
    if (!templateValues) {
      return [];
    }
    return SortAndFilterWashes(templateValues.washes);
  }, [templateValues]);

  return (
    <Page title={title} subHeaderContentLeft={subHeaderLeft} subHeaderContentRight={subHeaderRight}>
      <Box display="flex" flexDirection="row" py={6}>
        <Box flex="1" width="50%">
          {templateValues && boundaryValues && (
            <ExperimentForm experiment={templateValues} boundaryValues={boundaryValues} isTemplate viewMode />
          )}
        </Box>
        <Box flex="1" width="50%">
          {templateValues &&
          mappedCSVData &&
          mappedCSVData.length &&
          templateValues.detergent_dosing !== 'fixed_dose' ? (
            <WashTable mappedCsv={mappedCSVData} setCsvValidationError={setExperimentErrorMessage} viewMode />
          ) : null}
          {templateValues && templateValues.id ? (
            <SwatchTable viewMode={true} swatchData={templateValues.swatch_design} />
          ) : null}
          {templateValues && templateValues.id ? (
            <BallastTable viewMode={true} ballastData={templateValues.ballast_design} />
          ) : null}
        </Box>
      </Box>
      <>
        {loading ? <LoadingSpinner /> : null}
        {!loading && !templateValues ? (
          <>
            <Box textAlign="center" marginTop="2rem">
              You do not have access to the template you are trying to load.
            </Box>
            <Box textAlign="center">
              <a href="/">
                <Button type="secondary">Go to the home page</Button>
              </a>
            </Box>
          </>
        ) : null}
        <Snackbar open={!!experimentErrorMessage} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error">
            {experimentErrorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={!!experimentSuccessMessage} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success">
            {experimentSuccessMessage}
          </Alert>
        </Snackbar>
      </>
      <CreateTemplateExperimentModal
        show={createExperimentModalOpen}
        onClose={() => setCreateExperimentModalOpen(false)}
        templateId={templateId}
      />
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDelete={handleDelete}
        message="Are you sure you want to delete this template?"
      />
    </Page>
  );
};

export default ExperimentComponent;
