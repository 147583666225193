/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiUtils from '../services/apiUtils';

export interface NewCollaborator {
  username: string;
  experiment_id: string;
  access_level: string;
}

export interface ChangeOwnerData {
  old_owner: string;
  new_owner: string;
  experiment_id: string;
}

export const getCollaborators = (id: string | null) => ApiUtils.get(`/collaborators?experiment_id=${id}`);

export const shareExperiment = (data: NewCollaborator) => ApiUtils.post('/share_experiment', data);

export const unshareExperiment = (data: { username: string; experiment_id: string | null }) =>
  ApiUtils.post('/unshare_experiment', data);

export const changeExperimentOwner = (data: ChangeOwnerData) => ApiUtils.post('/change_owner_experiment', data);
