import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';
import React, { ReactElement, useEffect, useState } from 'react';
import { Experiment } from '../../utils/experimentUtils';
import { Template } from '../../utils/templateUtils';
import { getTimingCalculationString } from '../../utils/timingCalculationUtils';
import { getCalculatedWaterConsumption } from '../../utils/waterConsumptionUtils';

interface SummaryBoxProps {
  experiment: Experiment | Template;
}

const useStyles = makeStyles((theme: Theme) => ({
  summaryBox: {
    backgroundColor: colors.softGreen,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '8px',
    maxWidth: '340px',
    boxShadow: '-1px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}));

const SummaryBox = ({ experiment }: SummaryBoxProps): ReactElement => {
  const classes = useStyles();
  const [estimatedTime, setEstimatedTime] = useState('');
  const [waterConsumption, setWaterConsumption] = useState(0);

  useEffect(() => {
    if (experiment) {
      getTimingCalculationString(experiment).then(({ estimatedTime, total_rinse_volume }) => {
        setEstimatedTime(estimatedTime);
        setWaterConsumption(getCalculatedWaterConsumption(experiment, total_rinse_volume));
      });
    }
  }, [experiment]);

  return (
    <Box className={classes.summaryBox}>
      <Typography variant="subtitle1">Summary</Typography>
      Estimated time: {estimatedTime}
      <br />
      Water consumption: {waterConsumption} L
    </Box>
  );
};

export default SummaryBox;
