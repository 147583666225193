import React, { ReactElement, useEffect, useState, Suspense } from 'react';
import SharingTable, { Collaborator } from '../components/Sharing/SharingTable';
import { Box, makeStyles } from '@material-ui/core';
import { Button, Left } from '@novozymes-digital/components';
import { getExperiment } from '../utils/experimentUtils';
import { getCollaborators } from '../utils/sharingUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ShareWithModal from '../components/Sharing/ShareWithModal';
import LoadingSpinner from '../components/LoadingSpinner';
import { userState } from '../store/authState';
import { Snackbar, Alert } from '../components/toaster';
import Page from '../components/layout/Page';
import ButtonWithIcon from '../components/ButtonWithIcon';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moreIconButton: {
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      cursor: 'pointer',
    },
  },
}));

const Sharing = (): ReactElement => {
  const classes = useStyles();
  const [experimentId, setExperimentId] = useState<string>('');
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [shareWithModalOpen, setShareWithModalOpen] = useState<boolean>(false);
  const [loadingSharingPage, setLoadingSharingPage] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [showSharingPageSuccessMessage, setShowSharingPageSuccessMessage] = useState<boolean>(false);
  const [sharingPageSuccessMessage, setSharingPageSuccessMessage] = useState<string>('');
  const user = useRecoilValue(userState);
  const { experiment_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (experiment_id) {
      setLoadingSharingPage(true);
      setExperimentId(experiment_id);
      getExperiment(experiment_id).then(() => {
        setLoadingSharingPage(false);
      });
      getCollaboratorList(experiment_id);
    }
  }, [experiment_id]);

  const getCollaboratorList = (id: string) => {
    setLoadingSharingPage(true);
    getCollaborators(id)
      .then((res) => {
        setCollaborators(res.data);
        setLoadingSharingPage(false);
      })
      .catch(() => {
        setLoadingSharingPage(false);
      });
  };

  useEffect(() => {
    const isCurrentUserOwner = collaborators.some(
      (c: Collaborator) => c.username === user.attributes.email.toLowerCase() && c.access_level === 'owner'
    );
    setIsOwner(isCurrentUserOwner);
  }, [collaborators, user]);

  const handleCloseShareSuccess = () => {
    setShowSharingPageSuccessMessage(false);
    setSharingPageSuccessMessage('');
  };

  const handleBackClick = () => {
    navigate(`/experiment/${experimentId}`);
  };

  const subHeaderLeft = (
    <>
      <ButtonWithIcon
        id="btn-back-to-experiment"
        title="Back to experiment"
        icon={<Left />}
        onClick={handleBackClick}
      />
    </>
  );

  return (
    <Page subHeaderContentLeft={subHeaderLeft}>
      <Box margin="1rem 5rem">
        <Box className={classes.titleRow}>
          <Box fontSize="1.125rem" fontWeight="bold">
            Sharing
          </Box>
          <Button onClick={() => setShareWithModalOpen(true)} small>
            Share with
          </Button>
        </Box>
        {loadingSharingPage ? (
          <LoadingSpinner />
        ) : (
          <SharingTable
            collaborators={collaborators}
            getCollaboratorList={getCollaboratorList}
            isOwner={isOwner}
            setShowSharingPageSuccessMessage={() => setShowSharingPageSuccessMessage(true)}
            setSharingPageSuccessMessage={setSharingPageSuccessMessage}
            currentUserUsername={user.attributes.email.toLowerCase()}
            experimentId={experimentId}
          />
        )}
      </Box>
      <Suspense fallback={''}>
        <ShareWithModal
          show={shareWithModalOpen}
          onClose={() => setShareWithModalOpen(false)}
          experimentId={experimentId}
          getCollaboratorList={getCollaboratorList}
          setShowSharingPageSuccessMessage={() => setShowSharingPageSuccessMessage(true)}
          setSharingPageSuccessMessage={setSharingPageSuccessMessage}
          currentUserUsername={user.attributes.email.toLowerCase()}
          isOwner={isOwner}
        />
      </Suspense>
      <Snackbar open={showSharingPageSuccessMessage} autoHideDuration={5000} onClose={() => handleCloseShareSuccess()}>
        <Alert onClose={() => handleCloseShareSuccess()} severity="success">
          {sharingPageSuccessMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default Sharing;
