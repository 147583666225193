import { Box, createStyles, withStyles } from '@material-ui/core';

export const GroupBox = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'rgba(226, 232, 234,1)',
      borderRadius: '8px',
      padding: '16px 24px',
      boxShadow:
        '-1px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  })
)(Box);
