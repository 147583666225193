import React, { ReactElement } from 'react';
import { Modal, Box, Card, makeStyles } from '@material-ui/core';
import { Button } from '@novozymes-digital/components';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '1.5rem',
    width: '100%',
    maxWidth: 400,
    borderRadius: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  modalActions: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  closeModalButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface SaveConflictModalProps {
  open: boolean;
  onClose: () => void;
  onAction: (action: string) => void;
}

const SaveConflictModal = (props: SaveConflictModalProps): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open, onClose, onAction } = props;

  return (
    <Modal open={open} onClose={onClose} className={classes.modalRoot}>
      <Card className={classes.modalBody}>
        <Box display="flex" justifyContent="center">
          Someone else has edited this experiment since you last saved it.
        </Box>
        <Box className={classes.modalActions}>
          <Button onClick={() => onAction('view')} small>
            View
          </Button>
          <Button onClick={() => onAction('refresh')} small>
            Refresh
          </Button>
          <Button small onClick={() => navigate('/')}>
            Close
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default SaveConflictModal;
