import React, { ReactElement, useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../store/authState';
import { Experiment, duplicateExperiment, getValidExperimentsByUser } from '../../utils/experimentUtils';
import { Snackbar, Alert } from '../toaster';
import { userExperimentsState } from '../../store/experimentState';
import { useNavigate } from 'react-router-dom';

import { CreateExperimentForm, ExperimentInitialFields } from '../common/CreateExperimentForm';

export interface NewExperimentDialogProps {
  originalExperiment?: Experiment;
  onClose(): void;
}

const defaultValues: ExperimentInitialFields = {
  name: '',
  eln: '',
  project_id: '',
  region: '',
  scientist: [],
  target_customer: '',
  technician: [],
};

const DuplicateExperimentModal = (props: NewExperimentDialogProps): ReactElement => {
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [experimentInitialValues, setExperimentInitialValues] = useState<ExperimentInitialFields>(defaultValues);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const setUserExperiments = useSetRecoilState<Experiment[]>(userExperimentsState);

  const updateExperimentList = () => {
    if (user.attributes.email) {
      getValidExperimentsByUser(user.attributes.email.toLowerCase()).then((res: Experiment[]) => {
        setUserExperiments(res);
      });
    }
  };

  const { onClose, originalExperiment } = props;

  const handleExperimentSubmit = () => {
    const experimentObj = {
      created_by: user.attributes.email.toLowerCase(),
      id: originalExperiment?.id ?? null,
      ...experimentInitialValues,
    };
    duplicateExperiment(experimentObj)
      .then(() => {
        updateExperimentList();
        onClose();
        navigate('/');
      })
      .catch(() => {
        setShowError(true);
      });
  };

  useEffect(() => {
    originalExperiment &&
      setExperimentInitialValues((currentState) => ({
        ...currentState,
        name: originalExperiment.name + ' (Copy)',
      }));
  }, [originalExperiment]);

  return (
    <>
      <Dialog open={originalExperiment ? true : false} onClose={onClose} disableAutoFocus disableEnforceFocus>
        <CreateExperimentForm
          handleExperimentSubmit={handleExperimentSubmit}
          fields={experimentInitialValues}
          onClose={onClose}
          updateField={(key, value) => {
            setExperimentInitialValues({
              ...experimentInitialValues,
              [key]: value,
            });
          }}
        />
      </Dialog>
      <Snackbar open={showSuccess} autoHideDuration={5000} onClose={() => setShowSuccess(false)}>
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Successfully duplicated experiment
        </Alert>
      </Snackbar>
      <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error">
          Something went wrong duplicating your experiment. Please try again or contact the administrator.
        </Alert>
      </Snackbar>
    </>
  );
};

export default DuplicateExperimentModal;
