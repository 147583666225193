import { atom } from 'recoil';
import { Experiment } from '../utils/experimentUtils';

export const userExperimentsState = atom<Experiment[]>({
  key: 'userExperiments',
  default: [],
});

export const templateCreatedState = atom<boolean>({
  key: 'templateCreated',
  default: false,
});
