import { useEffect, useState } from 'react';
import { EnzymeMetaType } from '../../../../services/apiTypes';

function usePagination(data: EnzymeMetaType[], itemsPerPage: number, page: number) {
  const [filteredData, setFilteredData] = useState<EnzymeMetaType[]>([]);
  const [currentPage, setCurrentPage] = useState(page);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    const filteredData = data.filter((_, index) => index >= begin && index < end);
    setFilteredData(filteredData);
  }, [currentPage, data, itemsPerPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, filteredData, currentPage, maxPage };
}

export default usePagination;
