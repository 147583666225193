import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Close } from '@novozymes-digital/components';
import { Box, makeStyles, Drawer } from '@material-ui/core';
import { EnzymesForm } from './EnzymesForm';
import { EnzymeMetaType } from '../../../../services/apiTypes';
import DeleteModal from '../../../DeleteModal';
import { Pagination } from '@material-ui/lab';
import usePagination from './usePagination';

const useStyles = makeStyles(() => ({
  drawerBody: {
    padding: '24px',
    minWidth: '60rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawerPaper: {
    maxWidth: '90%',
  },
  drawerActions: {
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
  drawerContent: {
    padding: '24px',
    height: '100%',
    overflow: 'auto',
  },
}));

interface EnzymeDrawerProps {
  handleSaveData: (ballastData: EnzymeMetaType[]) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
  enzymesFields: EnzymeMetaType[];
  handleUpdateValue: (key: keyof EnzymeMetaType, newValue: string | number, enzymeIdx: number) => void;
  viewMode: boolean;
}

const PER_PAGE = 5;

export function EnzymeDrawer({
  handleUpdateValue,
  handleCloseModal,
  handleSaveData,
  isOpen,
  enzymesFields,
  viewMode,
}: EnzymeDrawerProps): ReactElement {
  const classes = useStyles();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState(false);

  const updateValue = useCallback(
    (key: keyof EnzymeMetaType, newValue: string | number, rowKey: string) => {
      setFormIsChanged(true);
      const enzymeIdx = enzymesFields.findIndex((field) => field.enzyme_key === rowKey);
      handleUpdateValue(key, newValue, enzymeIdx);
    },
    [enzymesFields, handleUpdateValue]
  );

  const { currentPage, jump, filteredData } = usePagination(enzymesFields, PER_PAGE, 1);

  const disableButton = useMemo(() => {
    return enzymesFields.some((fields) => {
      if (
        !fields.enzyme_name ||
        !fields.enzyme_type ||
        !fields.enzyme_batch ||
        !fields.enzyme_stock_concentration ||
        !fields.enzyme_key
      ) {
        return true;
      }
    });
  }, [enzymesFields]);

  const handleChangePage = (_: React.ChangeEvent<unknown>, page: number) => {
    jump(page);
  };

  const count = useMemo(() => Math.ceil(enzymesFields.length / PER_PAGE), [enzymesFields]);

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      classes={{ paper: classes.drawerPaper }}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
      }}
    >
      <Box className={classes.drawerBody}>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="1rem" fontWeight="bold" marginLeft="1.5rem">
            Enzymes
          </Box>
          <Close
            className={classes.closeButton}
            onClick={() => {
              if (formIsChanged) {
                setDeleteModalOpen(true);
              } else {
                handleCloseModal();
              }
            }}
          />
        </Box>

        <Box className={classes.drawerContent}>
          {filteredData.map((enzyme) => (
            <EnzymesForm
              key={enzyme.enzyme_key}
              fields={enzyme}
              handleUpdateValue={(key, newValue) => updateValue(key, newValue, enzyme.enzyme_key)}
              viewMode={viewMode}
            />
          ))}
        </Box>

        {count > 1 && (
          <Pagination count={count} page={currentPage} variant="outlined" shape="rounded" onChange={handleChangePage} />
        )}

        <Box className={classes.drawerActions}>
          <Button
            small
            onClick={() => {
              handleSaveData(enzymesFields);
              handleCloseModal();
              setFormIsChanged(false);
            }}
            disabled={!formIsChanged || disableButton}
          >
            Save data
          </Button>
        </Box>
      </Box>
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDelete={() => {
          setDeleteModalOpen(false);
          setFormIsChanged(false);
          handleCloseModal();
        }}
        message="Are you sure you want to discard your changes?"
        primaryButtonName="Discard"
      />
    </Drawer>
  );
}
