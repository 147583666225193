import React, { ReactElement, useMemo, useState } from 'react';
import { colors, DropDown, OutlinedInputWithLabel } from '@novozymes-digital/components';
import { Box, makeStyles, Typography, Theme, AccordionDetails, Accordion, AccordionSummary } from '@material-ui/core';
import { EnzymeMetaType } from '../../../../services/apiTypes';

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    height: 'calc(100% - 7rem)',
    overflowY: 'auto',
    justifyContent: 'space-between',
    margin: '2rem',
  },

  /* New */
  advancedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #E6E6E6',
  },
  advancedToggle: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  error: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: colors.errorRed,
  },
  moreFieldsLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  inputWrapper: {
    width: '15rem',
    height: '80px',
    marginRight: theme.spacing(2),
  },
}));

interface EnzymesFormProps {
  fields: EnzymeMetaType;
  handleUpdateValue: (key: keyof EnzymeMetaType, newValue: string | number) => void;
  viewMode: boolean;
}

export function EnzymesForm({ fields, handleUpdateValue, viewMode }: EnzymesFormProps): ReactElement {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [formValues, setFormValues] = useState<EnzymeMetaType>(fields);

  const toggleAccordion = () => {
    if (!hasEmptyFields) {
      setExpanded((prev) => !prev);
    }
  };

  const hasEmptyFields = useMemo(() => {
    if (
      !fields.enzyme_name ||
      !fields.enzyme_type ||
      !fields.enzyme_batch ||
      !fields.enzyme_stock_concentration ||
      !fields.enzyme_key
    ) {
      setExpanded(true);
      return true;
    } else {
      setExpanded(false);
      return false;
    }
  }, [fields]);

  return (
    <Accordion expanded={expanded} onChange={toggleAccordion}>
      <AccordionSummary expandIcon={<DropDown />} aria-controls="panel1a-content" id={`${formValues.enzyme_name}`}>
        <Box display="flex" justifyContent="space-between" width={'100%'}>
          <Typography className={classes.groupLabel}>{formValues.enzyme_name}</Typography>
          {hasEmptyFields && <Typography className={classes.error}>Please fill all required fields</Typography>}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.modalContent}>
          <Box mb={2}>
            <Box display="inline-flex" pb={2}>
              <Box className={classes.inputWrapper}>
                <OutlinedInputWithLabel
                  label={'Enzyme name'}
                  value={formValues.enzyme_name || ''}
                  type="text"
                  handleChange={(event) => {
                    setFormValues({ ...formValues, enzyme_name: event.target.value });
                  }}
                  onBlur={() => {
                    handleUpdateValue('enzyme_name', formValues.enzyme_name);
                  }}
                  disabled={viewMode}
                  tooltip={'PS0001, TLL'}
                />
              </Box>
              <Box className={classes.inputWrapper}>
                <OutlinedInputWithLabel
                  label={'Enzyme type'}
                  value={formValues.enzyme_type || ''}
                  type="text"
                  handleChange={(event) => {
                    setFormValues({ ...formValues, enzyme_type: event.target.value });
                  }}
                  onBlur={() => {
                    handleUpdateValue('enzyme_type', formValues.enzyme_type);
                  }}
                  disabled={viewMode}
                  tooltip={'Protease, lipase, Medley etc'}
                />
              </Box>
            </Box>
          </Box>
          <Box display="inline-flex" pb={2}>
            <Box className={classes.inputWrapper}>
              <OutlinedInputWithLabel
                label={'Enzyme batch'}
                value={formValues.enzyme_batch || ''}
                type="text"
                handleChange={(event) => {
                  setFormValues({ ...formValues, enzyme_batch: event.target.value });
                }}
                onBlur={() => {
                  handleUpdateValue('enzyme_batch', formValues.enzyme_batch);
                }}
                disabled={viewMode}
                tooltip={'Seq ID'}
              />
            </Box>

            <Box className={classes.inputWrapper}>
              <OutlinedInputWithLabel
                label="Enzyme stock concentration"
                value={formValues.enzyme_stock_concentration || ''}
                adornment="mg/ml"
                type="number"
                handleChange={(event) => {
                  setFormValues({ ...formValues, enzyme_stock_concentration: parseFloat(event.target.value) });
                }}
                onBlur={() => {
                  handleUpdateValue('enzyme_stock_concentration', formValues.enzyme_stock_concentration);
                }}
                disabled={viewMode}
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
