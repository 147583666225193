/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  BallastDesignType,
  BallastMetaType,
  ConfidentialityEnum,
  EnzymeDesignType,
  EnzymeMetaType,
  IngredientCategoryEnum,
  MatchingType,
  SwatchDesignType,
  SwatchMetaType,
} from '../services/apiTypes';
import ApiUtils from '../services/apiUtils';
import { Wash } from './experimentUtils';

export interface Template {
  created_by: string;
  detergent_dose_per_beaker: number;
  detergent_dosing: string;
  detergent_number: number;
  enzyme_plate_1_addition: boolean;
  enzyme_plate_2_addition: boolean;
  id: number | null;
  name: string;
  stain_type_number: number;
  soaking_time: number;
  valid_from: number;
  valid_till?: number;
  wash_agitation_speed: number;
  wash_time: number;
  wash_volume: number;
  wash_water_temperature: number;
  washes: Array<Wash>;
  beaker_jacket_temperature: number;
  batch_id: string;
  component_name: string;
  is_batch: boolean;
  number_of_washes: number;
  hardness_dh: number;
  matching: MatchingType[];
  hardness_ratio_ca: number;
  hardness_ratio_hco3: number;
  hardness_ratio_mg: number;
  titration: boolean;
  target_ph: number;
  groups?: string[];
  confidentiality?: ConfidentialityEnum;
  release_date?: string;
  dilution_factor: number;
  swatch_design: SwatchDesignType[];
  ballast_design: BallastDesignType[];
  ballast_metadata: BallastMetaType[];
  swatch_metadata: SwatchMetaType[];
  ingredient_category?: IngredientCategoryEnum;
  ingredient_type?: string;
  ballast_type_number: number;
  ballast_file_name?: string;
  enzyme_design_1: EnzymeDesignType[];
  enzyme_design_2: EnzymeDesignType[];
  enzyme_metadata_1: EnzymeMetaType[];
  enzyme_metadata_2: EnzymeMetaType[];
}

export const getTemplates = () => ApiUtils.get('/templates');

export const getTemplate = (id: string) => ApiUtils.post('/get_template', { id });

export const deleteTemplate = (id: number | null) => ApiUtils.delete('/delete_template', { id });

interface CreateExperimentFromTemplateInput {
  template_id: number | null;
  experiment_name: string;
  eln: string;
}

export const createExperimentFromTemplate = (obj: CreateExperimentFromTemplateInput) =>
  ApiUtils.post('/create_templated_experiment', obj);

interface CreateTemplateInput {
  template_name: string;
  experiment_id: number | null;
}

export const createTemplate = (obj: CreateTemplateInput) => ApiUtils.post('/create_template', obj);
