import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ExperimentPage from './pages/Experiment';
import SharingPage from './pages/Sharing';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Page from './components/layout/Page';
import config from './config/cognito.json';
import { isAuthenticatedState, userState } from './store/authState';
import { getUser } from './utils/getUser';
import { userExperimentsState } from './store/experimentState';
import { Experiment, getValidExperimentsByUser } from './utils/experimentUtils';
import LoadingSpinner from './components/LoadingSpinner';
import TemplatePage from './pages/Template';
import HelpPage from './components/Help/Help';
import { Auth, Amplify } from 'aws-amplify';

const authConfig = {
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  mandatorySignIn: false,

  oauth: {
    domain: config.cognito.AWS_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_DOMAIN,
    redirectSignOut: process.env.REACT_APP_DOMAIN,
    responseType: 'code',
  },
};

Auth.configure({
  Auth: authConfig,
});

Amplify.configure(authConfig);

function App(): ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const setAuthenticated = useSetRecoilState(isAuthenticatedState);
  const isAuthState = useRecoilValue(isAuthenticatedState);
  const setUserState = useSetRecoilState(userState);
  const setUserExperiments = useSetRecoilState(userExperimentsState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    const setAuth = async () => {
      let authSession;
      setLoading(true);
      try {
        authSession = await Auth.currentSession();
      } catch (e) {}

      if (authSession) {
        setAuthenticated(true);
      } else {
        try {
          await Auth.federatedSignIn({ customProvider: config.cognito.COGNITO_IDENTITY_PROVIDER });
        } catch (e) {}
      }
      setLoading(false);
    };
    setAuth();
  }, [setAuthenticated, setUserState]);

  useEffect(() => {
    isAuthState &&
      getUser()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          const groups =
            res?.attributes['custom:ad_groups']
              .replace(/['\[\]]/g, '')
              .split(', ')
              .sort() ?? [];

          res.attributes['custom:ad_groups'] = groups;

          if (res) setUserState(res);
        })
        .catch(() => {
          setLoading(false);
        });
  }, [isAuthState, setUserState]);

  useEffect(() => {
    if (user.attributes.email) {
      getValidExperimentsByUser(user.attributes.email.toLowerCase()).then((res: Experiment[]) => {
        setUserExperiments(res);
      });
    }
  }, [user.attributes.email, setUserExperiments]);

  return (
    <Router>
      {isLoading ? (
        <Page>
          <LoadingSpinner />
        </Page>
      ) : (
        <>
          {isAuthState && (
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/experiment/:experiment_id" element={<ExperimentPage />} />
              <Route path="/sharing/:experiment_id" element={<SharingPage />} />
              <Route path="/template/:experiment_id" element={<TemplatePage />} />
              <Route
                path="/help"
                element={
                  <Page>
                    <HelpPage />
                  </Page>
                }
              />
            </Routes>
          )}
        </>
      )}
    </Router>
  );
}

export default App;
