import React, { ReactElement } from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  loadingWrapper: {
    width: '100%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function LoadingSpinner(): ReactElement {
  const classes = useStyles();
  return (
    <Box className={classes.loadingWrapper}>
      <img src="/loading.svg" alt="Loading..." />
    </Box>
  );
}
