import React, { ReactNode, ReactElement, useRef } from 'react';
import { Container, makeStyles, Box, RootRef } from '@material-ui/core';
import { Footer } from '@novozymes-digital/components';
import Header from './Header';
import SubHeader from './SubHeader';

interface PageProps {
  children: NonNullable<ReactNode>;
  title?: ReactNode;
  subHeaderContentLeft?: ReactNode;
  subHeaderContentRight?: ReactNode;
}

const FOOTER_HEIGHT = '46px';
const HEADER_HEIGHT = '64px';
const SUB_HEADER_HEIGHT = '49px';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    display: 'relative',
    height: 'calc(100vh - 6.6rem)',
    overflowY: 'scroll',
  },
  scrollContainer: (props: PageProps) => {
    const subHeaderHeightSub =
      !!props.subHeaderContentLeft || !!props.subHeaderContentRight ? `- ${SUB_HEADER_HEIGHT}` : '';
    return {
      height: `calc(100vh - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT} ${subHeaderHeightSub})`,
      overflowY: 'auto',
      overflowX: 'hidden',
    };
  },
}));

const Page = (props: PageProps): ReactElement => {
  const { children, title, subHeaderContentLeft, subHeaderContentRight } = props;
  const classes = useStyles(props);

  const scrollContainerRef = useRef<unknown>();
  return (
    <Box className={classes.root}>
      <Header title={title} />
      {(!!subHeaderContentLeft || !!subHeaderContentRight) && (
        <SubHeader leftSideContent={subHeaderContentLeft} rightSideContent={subHeaderContentRight} />
      )}
      <RootRef rootRef={scrollContainerRef}>
        <Box className={classes.scrollContainer}>
          <Container>{children}</Container>
        </Box>
      </RootRef>
      <Footer />
    </Box>
  );
};

export default Page;
