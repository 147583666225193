import React, { ReactElement } from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '3rem 6.25rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  content: {
    fontSize: '1rem',
    margin: '1rem 0 2rem',
  },
});

const Help = (): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>About</Box>
      <Box className={classes.content}>
        This application is intended for scientists and technicians to use for planning of experiments in the A-TOM
        equipment (Automated TOM).
      </Box>
      <Box className={classes.title}>Contact / Access request</Box>
      <Box className={classes.content}>
        Please reach out if you have any issues, feedback or want to request access to Proton:
      </Box>
      <Box marginLeft="2rem">
        <li>Sara Mesquita (SMQ)</li>
      </Box>
    </Box>
  );
};

export default Help;
