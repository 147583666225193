import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button } from '@novozymes-digital/components';
import { Box } from '@material-ui/core';

import { StainDrawer } from '../common/StainDrawer';
import { SwatchMetaType } from '../../../../services/apiTypes';
import { addSwatchMetadata, getSwatchMetadata } from '../../../../utils/experimentUtils';
import produce from 'immer';

interface FixedStainTypeProps {
  numberOfComponents: number;
  disabled?: boolean;
  experimentId: number | null;
}

export const FixedStainType = ({ numberOfComponents, disabled, experimentId }: FixedStainTypeProps): ReactElement => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [swatchFields, setSwatchFields] = useState<SwatchMetaType[]>([]);

  useEffect(() => {
    const fetchSwatches = async () => {
      if (experimentId) {
        const res = await getSwatchMetadata(experimentId);

        if (res.status === 200 && res.data.length > 0) {
          setSwatchFields(res.data);
        } else {
          const swatches: SwatchMetaType[] = [];
          for (let index = 0; index < numberOfComponents; index++) {
            swatches.push({
              stain_id: `Swatch type ${index + 1}`,
              pieces_of_swatches_pr_container: 1,
              stain_batch: '',
              stain_types: `Swatch type ${index + 1}`,
              swatch_sides_measured: 1,
              measurement_equipment: [],
              measurement_method: '',
            });
          }
          setSwatchFields(swatches);
        }
      }
    };

    if (drawerIsOpen) {
      fetchSwatches();
    }
  }, [drawerIsOpen, experimentId, numberOfComponents]);

  const handleUpdateValue = useCallback(
    (key: keyof SwatchMetaType, newValue: string | number | string[], selectedStainTab: number) => {
      const nextState = produce(swatchFields, (draftState) => {
        if (draftState[selectedStainTab]) {
          draftState[selectedStainTab] = { ...draftState[selectedStainTab], [key]: newValue };
        }
      });
      setSwatchFields(nextState);
    },
    [swatchFields]
  );

  const handleSaveData = (swatchData: SwatchMetaType[]) => {
    if (experimentId) {
      addSwatchMetadata(experimentId, swatchData);
    }
  };

  return (
    <>
      <Box display="flex">
        <Button small disabled={disabled} onClick={() => setDrawerIsOpen(true)}>
          Fixed
        </Button>
      </Box>

      <StainDrawer
        type="fixed"
        isOpen={drawerIsOpen}
        swatchFields={swatchFields}
        handleCloseModal={() => setDrawerIsOpen(false)}
        handleSaveData={handleSaveData}
        handleUpdateValue={handleUpdateValue}
      />
    </>
  );
};
