import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Slider, Theme, Typography } from '@material-ui/core';
import {
  colors,
  DropDown,
  DropUp,
  OutlinedInputWithLabel,
  SelectWithLabel,
  ToggleSwitch,
} from '@novozymes-digital/components';
import React, { ReactElement, useState, useCallback, useMemo } from 'react';

import { FieldBoundary } from '../../services/getBoundaryValues';
import { Experiment, HardnessParametersType, Wash } from '../../utils/experimentUtils';
import CSVUpload from './CSVUpload';
import TooltipComponent from '../Tooltip';
import SummaryBox from './SummaryBox';
import TimeField from './TimeField';
import { useEffect } from 'react';
import { Template } from '../../utils/templateUtils';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/authState';
import {
  BallastDesignType,
  ConfidentialityEnum,
  EnzymeDesignType,
  IngredientCategoryEnum,
  MatchingType,
  SwatchDesignType,
} from '../../services/apiTypes';
import { GroupBox } from '../GroupBox';
import { FixedBallast, VariableBallast } from './Ballast';
import { IngredientCategoryOptionsType } from './CsvUploadModal';
import { SelectOption } from '../../utils/commonUtils';
import { getUsers } from '../../utils/getUser';
import { FixedStainType, VariableType } from './StainTypes';
import { EnzymeBeaker, EnzymeGroupType } from './Enzymes';

interface ExperimentFormProps {
  experiment: Experiment | Template;
  isTemplate?: boolean;
  onExperimentUpdate?: (experiment: Experiment | Template) => void;
  validationErrors?: Record<keyof Experiment, boolean>;
  boundaryValues: Record<string, FieldBoundary>;
  viewMode?: boolean;
  setExperimentErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  hardnessParameters?: HardnessParametersType;
  handleChangeSwatches?: (swatches: SwatchDesignType[]) => void;
  handleChangeBallasts?: (ballasts: BallastDesignType[]) => void;
  swatchData?: SwatchDesignType[];
  ballastData?: BallastDesignType[];
  handleChangeEnzymes?: (enzymes: EnzymeDesignType[], group: EnzymeGroupType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  advancedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #E6E6E6',
  },
  inputWrapper: {
    width: '10.5rem',
    height: '80px',
    marginRight: theme.spacing(2),
  },
  inputLabel: {
    marginBottom: '1rem',
    height: '1.5rem',
    color: colors.black80,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
  radioButtonLabel: {
    fontSize: '0.825rem',
  },
  sliderWrapper: {
    maxWidth: '340px',
  },
  advancedToggle: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const getFormatedUnit = (unit: string): string => {
  switch (unit) {
    case 'celsius':
      return ' °C';
    case 'count':
      return '';
    default:
      return ' ' + unit;
  }
};

type DetergentMixture = 'component_mixture' | 'fixed_dose';

const optionsDetergentNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const optionsStainTypesNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const optionsBallastTypesNumber = [0, 1, 2, 3, 4];

const ExperimentForm = ({
  experiment,
  isTemplate,
  onExperimentUpdate,
  boundaryValues,
  validationErrors,
  viewMode = false,
  setExperimentErrorMessage,
  hardnessParameters,
  handleChangeSwatches,
  handleChangeBallasts,
  ballastData = [],
  swatchData = [],
  handleChangeEnzymes,
}: ExperimentFormProps): ReactElement => {
  const classes = useStyles();
  const [detergentMixture, setDetergentMixture] = useState<DetergentMixture>(
    experiment.detergent_dosing as DetergentMixture
  );
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [confidentiality, setConfidentiality] = useState<ConfidentialityEnum>(
    experiment.confidentiality ?? ConfidentialityEnum.sharable
  );

  const [ingredientCategory, setIngredientCategory] = useState<IngredientCategoryEnum>(
    experiment.ingredient_category ?? IngredientCategoryEnum.detergent
  );

  const [releaseDate, setReleaseDate] = useState<string | undefined>(experiment.release_date);
  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
  const user = useRecoilValue(userState);

  useEffect(() => {
    setDetergentMixture(experiment.detergent_dosing as DetergentMixture);
  }, [experiment.detergent_dosing]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUsers();

      if (response.status === 200) {
        const users = response.data.users.map((user) => ({ label: user, key: user, value: user }));
        setUserOptions(users);
      }
    };

    if (!isTemplate) {
      fetchUsers();
    }
  }, [isTemplate]);

  const handleValueUpdate = useCallback(
    (experimentKey: keyof Experiment | keyof Template) =>
      (event: React.ChangeEvent<HTMLInputElement | { value: number | string }>) => {
        if (onExperimentUpdate) {
          onExperimentUpdate({
            ...experiment,
            [experimentKey]: event.target.value,
          });
        }
      },
    [experiment, onExperimentUpdate]
  );

  const handleRatioUpdate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | { value: number | string }>) => {
      if (onExperimentUpdate) {
        let hardness_ratio_ca = experiment.hardness_ratio_ca;
        let hardness_ratio_hco3 = experiment.hardness_ratio_hco3;
        let hardness_ratio_mg = experiment.hardness_ratio_mg;
        if (hardnessParameters?.[event.target.value as number]) {
          hardness_ratio_ca = hardnessParameters[event.target.value as number].hardness_ratio_ca;
          hardness_ratio_hco3 = hardnessParameters[event.target.value as number].hardness_ratio_hco3;
          hardness_ratio_mg = hardnessParameters[event.target.value as number].hardness_ratio_mg;
        }
        onExperimentUpdate({
          ...experiment,
          hardness_dh: event.target.value as number,
          hardness_ratio_ca,
          hardness_ratio_hco3,
          hardness_ratio_mg,
        });
      }
    },
    [experiment, hardnessParameters, onExperimentUpdate]
  );

  const handleSliderValueUpdate = useCallback(
    (experimentKey: keyof Experiment) => (_: React.ChangeEvent<unknown>, newValue: number | number[]) => {
      if (onExperimentUpdate) {
        onExperimentUpdate({
          ...experiment,
          [experimentKey]: newValue,
        });
      }
    },
    [experiment, onExperimentUpdate]
  );

  const handleCustomValueUpdate = useCallback(
    (experimentKey: keyof Experiment, newValue: number | string | boolean | string[]) => {
      if (onExperimentUpdate) {
        onExperimentUpdate({
          ...experiment,
          [experimentKey]: newValue,
        });
      }
    },
    [experiment, onExperimentUpdate]
  );

  const handleCSVUpdateUpdate = useCallback(
    (washes: Wash[], matching: MatchingType[]) => {
      if (onExperimentUpdate) {
        onExperimentUpdate({
          ...experiment,
          matching,
          washes,
        });
      }
    },
    [experiment, onExperimentUpdate]
  );

  const handleGroupsUpdate = useCallback(
    (groups: string[]) => {
      if (onExperimentUpdate) {
        onExperimentUpdate({
          ...experiment,
          groups,
        });
      }
    },
    [experiment, onExperimentUpdate]
  );

  const handleDetergentDosingUpdate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | { value: number | string }>) => {
      const newVal = event.target.value;
      setDetergentMixture(newVal as DetergentMixture);
      handleCustomValueUpdate('detergent_dosing', newVal);
    },
    [handleCustomValueUpdate]
  );

  const getInputLabel = (fieldLabel: string, fieldKey: string) => {
    let minValue = boundaryValues[fieldKey].field_min_value;
    let maxValue = boundaryValues[fieldKey].field_max_value;
    let fieldUnit = boundaryValues[fieldKey].field_unit;

    if (fieldUnit === 'sec' && maxValue % 60 === 0 && minValue % 60 === 0) {
      fieldUnit = 'min';
      maxValue = maxValue / 60;
      minValue = minValue / 60;
    }

    return `${fieldLabel} (${minValue} - ${maxValue}${getFormatedUnit(fieldUnit)})`;
  };

  const handleMappedCSVUpdate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (mappedCsvData: any, matching: MatchingType[]) => {
      const washes =
        mappedCsvData &&
        mappedCsvData.map((obj: Record<string, string>, idx: number) => {
          return { wash_order_id: idx + 1, ...obj };
        });

      handleCSVUpdateUpdate(washes, matching);
    },
    [handleCSVUpdateUpdate]
  );

  const adGroups = useMemo(() => {
    return (
      user?.attributes['custom:ad_groups'].map((group) => ({
        label: group,
        value: group,
        key: group,
      })) ?? []
    );
  }, [user]);

  const confidentialityOptions = useMemo(
    () => [
      {
        label: 'Confidential and sharable',
        value: ConfidentialityEnum.sharable,
        key: ConfidentialityEnum.sharable,
      },
      {
        label: 'Confidential, non-shareable but will be released',
        value: ConfidentialityEnum.not_sharable,
        key: ConfidentialityEnum.not_sharable,
      },
      {
        label: 'Confidential and never released (50 years)',
        value: ConfidentialityEnum.never_sharable,
        key: ConfidentialityEnum.never_sharable,
      },
    ],

    []
  );

  const ingredientCategoryOptions = useMemo<IngredientCategoryOptionsType[]>(
    () => [
      {
        label: 'Detergent',
        value: IngredientCategoryEnum.detergent,
        key: IngredientCategoryEnum.detergent,
      },
      {
        label: 'Surfactant',
        value: IngredientCategoryEnum.surfactant,
        key: IngredientCategoryEnum.surfactant,
      },
      {
        label: 'Component',
        value: IngredientCategoryEnum.component,
        key: IngredientCategoryEnum.component,
      },
      {
        label: 'Enzyme',
        value: IngredientCategoryEnum.enzyme,
        key: IngredientCategoryEnum.enzyme,
      },
    ],

    []
  );

  useEffect(() => {
    if (onExperimentUpdate) {
      onExperimentUpdate({
        ...experiment,
        confidentiality,
        release_date: confidentiality === ConfidentialityEnum.not_sharable ? releaseDate : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confidentiality, releaseDate]);

  return (
    <Box className={classes.wrapper}>
      <Box display="flex" flexDirection="column">
        <Box display="inline-flex">
          <SummaryBox experiment={experiment} />
        </Box>
        <GroupBox mb={2} min-height={300}>
          <Typography className={classes.groupLabel}>Experiment</Typography>
          <Box display="inline-flex" pb={6}>
            <Box className={classes.inputWrapper}>
              <Typography className={classes.inputLabel}>Experiment title</Typography>
              <OutlinedInputWithLabel
                value={experiment?.name}
                placeholder="experiment title"
                handleChange={handleValueUpdate('name')}
                disabled={viewMode}
              />
            </Box>
            {!isTemplate && (
              <Box className={classes.inputWrapper}>
                <OutlinedInputWithLabel
                  label="Entry ID"
                  placeholder="Entry ID"
                  tooltip="Entry ID in format: ELNyyxxxxxx. Make sure to create an Entry before starting the Proton flow. These are available using Benchling."
                  value={(experiment as Experiment)?.eln}
                  handleChange={handleValueUpdate('eln')}
                  error={validationErrors?.eln}
                  errorMessage="Invalid Entry ID format"
                  disabled={viewMode}
                />
              </Box>
            )}
          </Box>

          {!isTemplate && (
            <Box display="flex" flexDirection={'column'}>
              <Box pb={2} display="inline-flex">
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label="Project ID"
                    value={(experiment as Experiment).project_id}
                    handleChange={handleValueUpdate('project_id')}
                    disabled={viewMode}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label="Target Customer"
                    value={(experiment as Experiment).target_customer}
                    handleChange={handleValueUpdate('target_customer')}
                    disabled={viewMode}
                  />
                </Box>
              </Box>
              <Box pb={2} display="inline-flex">
                <Box className={classes.inputWrapper}>
                  <SelectWithLabel
                    value={(experiment as Experiment).scientist ?? []}
                    multiple
                    label="Scientists"
                    options={userOptions}
                    disabled={viewMode}
                    placeholder="Please select..."
                    handleChange={(e) => {
                      handleCustomValueUpdate('scientist', e.target.value as string[]);
                    }}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <SelectWithLabel
                    value={(experiment as Experiment).technician ?? []}
                    multiple
                    label="Technicians"
                    options={userOptions}
                    placeholder="Please select..."
                    disabled={viewMode}
                    handleChange={(e) => {
                      handleCustomValueUpdate('technician', e.target.value as string[]);
                    }}
                  />
                </Box>
              </Box>
              <Box pb={2} display="inline-flex">
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label="Region"
                    value={(experiment as Experiment).region}
                    handleChange={handleValueUpdate('region')}
                    disabled={viewMode}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box width={'300px'} pb={2}>
            <Typography className={classes.inputLabel}>Collaboration space(s)</Typography>
            <SelectWithLabel
              value={experiment?.groups ?? []}
              options={adGroups}
              errorMessage="Multiple columns with same name"
              handleChange={(event) => handleGroupsUpdate(event.target.value as string[])}
              multiple
              disabled={viewMode}
            />
          </Box>
          <Box width={'300px'} pb={2}>
            <Typography className={classes.inputLabel}>Experiment confidentiality</Typography>
            <Box display="inline-flex">
              <Box width={300}>
                <SelectWithLabel
                  value={confidentiality}
                  options={confidentialityOptions}
                  handleChange={(e) => {
                    const value = e.target.value as ConfidentialityEnum;
                    setConfidentiality(value);
                  }}
                  disabled={viewMode}
                />
              </Box>

              {confidentiality === ConfidentialityEnum.not_sharable && (
                <Box pl={2}>
                  <OutlinedInputWithLabel
                    value={releaseDate}
                    type="datetime-local"
                    handleChange={(e) => {
                      const value = e.target.value as string;
                      setReleaseDate(value);
                    }}
                    disabled={viewMode}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>Detergent</Typography>
          <Box pb={2}>
            Detergent dosing
            <Box>
              <RadioGroup
                aria-label="Detergent dosing"
                name="component_mixture"
                value={detergentMixture}
                onChange={handleDetergentDosingUpdate}
                row
              >
                <FormControlLabel
                  value="fixed_dose"
                  classes={{ label: classes.radioButtonLabel }}
                  control={<Radio disableRipple disableFocusRipple color="primary" />}
                  label="Single detergent/Fixed dose"
                  disabled={viewMode}
                />
                <FormControlLabel
                  value="component_mixture"
                  disabled={viewMode}
                  classes={{ label: classes.radioButtonLabel }}
                  control={<Radio disableRipple disableFocusRipple color="primary" />}
                  label="Component mixture"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box pb={2}>
            {detergentMixture === 'fixed_dose' ? (
              <>
                <Box display="inline-flex" pb={2}>
                  <Box width={200}>
                    <SelectWithLabel
                      value={ingredientCategory}
                      label="Ingredient Category"
                      options={ingredientCategoryOptions}
                      handleChange={(e) => {
                        const value = e.target.value as IngredientCategoryEnum;
                        handleCustomValueUpdate('ingredient_category', value);
                        setIngredientCategory(value);
                      }}
                      disabled={viewMode}
                    />
                  </Box>
                  <Box pl={2}>
                    <OutlinedInputWithLabel
                      value={experiment.ingredient_type}
                      label="Ingredient Type"
                      type="text"
                      handleChange={handleValueUpdate('ingredient_type')}
                      disabled={viewMode}
                      tooltip="Surfactant: Nonionic, anionic, etc. Detergent: Powder, liquid , SUD, high, low tier, etc. Component: Inhibitor, polymer, etc"
                    />
                  </Box>
                </Box>
                <Box display="inline-flex" pb={2}>
                  <Box>
                    <OutlinedInputWithLabel
                      label="Detergent name"
                      value={experiment.component_name}
                      handleChange={handleValueUpdate('component_name')}
                      disabled={viewMode}
                    />
                  </Box>
                  <Box pl={2}>
                    <OutlinedInputWithLabel
                      label={'Batch id/no'}
                      placeholder="Batch id/no"
                      value={experiment.batch_id}
                      handleChange={handleValueUpdate('batch_id')}
                      disabled={viewMode}
                      type="text"
                    />
                  </Box>
                  <Box pl={2}>
                    <OutlinedInputWithLabel
                      label={'Dilution factor'}
                      placeholder="Dilution factor"
                      value={experiment.dilution_factor}
                      tooltip="Dilution is made before experiment start and dosage/beaker should be the final dosage including dilution factor"
                      handleChange={handleValueUpdate('dilution_factor')}
                      disabled={viewMode}
                      type="number"
                    />
                  </Box>
                </Box>

                <Box display="inline-flex">
                  <Box>
                    <OutlinedInputWithLabel
                      label={getInputLabel('Dosage/beaker', 'detergent_dose_per_beaker')}
                      value={experiment.detergent_dose_per_beaker}
                      adornment={boundaryValues['detergent_dose_per_beaker'].field_unit}
                      placeholder="0"
                      type="number"
                      handleChange={handleValueUpdate('detergent_dose_per_beaker')}
                      error={validationErrors?.detergent_dose_per_beaker}
                      errorMessage="Please insert a valid value"
                      disabled={viewMode}
                    />
                  </Box>
                  <Box pl={2}>
                    <OutlinedInputWithLabel
                      value={experiment.number_of_washes}
                      placeholder="0"
                      label="Total number of washes"
                      tooltip={`Includes total number of conditions and respective replicates. Maximum number of washes is ${boundaryValues['number_of_washes'].field_max_value}`}
                      type="number"
                      handleChange={handleValueUpdate('number_of_washes')}
                      error={validationErrors?.number_of_washes}
                      errorMessage="Invalid Input"
                      disabled={viewMode}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box>
                <Typography className={classes.inputLabel}>Number of components</Typography>
                <Box className={classes.sliderWrapper}>
                  <Slider
                    value={experiment.detergent_number}
                    onChangeCommitted={handleSliderValueUpdate('detergent_number')}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    disabled={viewMode}
                    step={1}
                    min={1}
                    max={10}
                    marks={optionsDetergentNumber.map((option) => ({
                      value: option,
                      label: option.toString(),
                    }))}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {detergentMixture === 'component_mixture' ? (
            <Box pb={2}>
              <CSVUpload
                numberOfComponents={experiment.detergent_number}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setMappedCsvData={handleMappedCSVUpdate as any}
                disabled={viewMode}
                setErrorMessage={setExperimentErrorMessage}
                fileName={(experiment as Experiment).detergent_file_name}
                handleCustomValueUpdate={handleCustomValueUpdate}
                ingredientCategoryOptions={ingredientCategoryOptions}
              />
            </Box>
          ) : (
            <></>
          )}
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>Stains</Typography>
          <Box>
            <Typography className={classes.inputLabel}>Number of swatch types</Typography>
            <Box className={classes.sliderWrapper}>
              <Slider
                value={experiment.stain_type_number}
                onChangeCommitted={handleSliderValueUpdate('stain_type_number')}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                disabled={viewMode || swatchData.length > 0}
                step={1}
                min={1}
                max={15}
                marks={optionsStainTypesNumber.map((option) => ({
                  value: option,
                  label: option.toString(),
                }))}
              />
            </Box>
          </Box>

          <Box display="inline-flex">
            {swatchData.length === 0 && (
              <Box pr={2}>
                <FixedStainType
                  numberOfComponents={experiment.stain_type_number ?? 1}
                  disabled={viewMode}
                  experimentId={experiment.id}
                />
              </Box>
            )}
            <Box>
              <VariableType
                numberOfComponents={experiment.stain_type_number ?? 1}
                handleChangeSwatches={handleChangeSwatches}
                swatchDesignData={swatchData}
                disabled={viewMode}
                setErrorMessage={setExperimentErrorMessage}
                experimentId={experiment.id}
                fileName={(experiment as Experiment).stain_file_name}
                handleCustomValueUpdate={handleCustomValueUpdate}
              />
            </Box>
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>Ballast</Typography>
          <Box>
            <Typography className={classes.inputLabel}>Number of ballast types</Typography>
            <Box className={classes.sliderWrapper}>
              <Slider
                value={experiment.ballast_type_number}
                onChangeCommitted={handleSliderValueUpdate('ballast_type_number')}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                disabled={viewMode || ballastData.length > 0}
                step={1}
                min={0}
                max={4}
                marks={optionsBallastTypesNumber.map((option) => ({
                  value: option,
                  label: option.toString(),
                }))}
              />
            </Box>
          </Box>

          <Box display="inline-flex">
            {ballastData.length === 0 && (
              <Box pr={2}>
                <FixedBallast
                  numberOfComponents={experiment.ballast_type_number}
                  disabled={viewMode || experiment.ballast_type_number === 0}
                  experimentId={experiment.id}
                />
              </Box>
            )}
            <Box>
              <VariableBallast
                numberOfComponents={experiment.ballast_type_number}
                handleChangeBallasts={handleChangeBallasts}
                ballastDesignData={ballastData}
                disabled={viewMode || experiment.ballast_type_number === 0}
                setErrorMessage={setExperimentErrorMessage}
                experimentId={experiment.id}
                fileName={(experiment as Experiment).ballast_file_name}
                handleCustomValueUpdate={handleCustomValueUpdate}
              />
            </Box>
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Box display="flex" flexDirection="column">
            <Box display="inline-flex" pb={2}>
              <Typography className={classes.groupLabel} style={{ marginBottom: 0 }}>
                Enzyme/beaker
              </Typography>
              <TooltipComponent title="Choose if you have maximum 1 or 2 enzyme pipettings per beaker" />
            </Box>
            <Box display="inline-flex" pb={2}>
              <EnzymeBeaker
                enzymeGroup="enzyme_plate_1_addition"
                handleCustomValueUpdate={handleCustomValueUpdate}
                handleChangeEnzymes={handleChangeEnzymes}
                experiment={experiment}
                viewMode={viewMode}
                setErrorMessage={setExperimentErrorMessage}
                fileName={experiment.enzyme_metadata_1[0]?.enzyme_file ?? ''}
              />
            </Box>
            <Box display="inline-flex">
              <EnzymeBeaker
                enzymeGroup="enzyme_plate_2_addition"
                handleCustomValueUpdate={handleCustomValueUpdate}
                experiment={experiment}
                viewMode={viewMode}
                setErrorMessage={setExperimentErrorMessage}
                handleChangeEnzymes={handleChangeEnzymes}
                fileName={experiment.enzyme_metadata_2[0]?.enzyme_file ?? ''}
              />
            </Box>
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>
            {getInputLabel('Temperature', 'wash_water_temperature')}
          </Typography>
          <Box className={classes.inputWrapper} style={{ height: 40 }}>
            <OutlinedInputWithLabel
              adornment={getFormatedUnit(boundaryValues['wash_water_temperature'].field_unit)}
              value={experiment.wash_water_temperature}
              placeholder="10"
              type="number"
              handleChange={handleValueUpdate('wash_water_temperature')}
              error={validationErrors?.wash_water_temperature}
              errorMessage="Invalid Input"
              disabled={viewMode}
            />
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>Wash Time</Typography>
          <Box display="inline-flex" pb={2}>
            <TimeField
              label={getInputLabel('Wash Time', 'wash_time')}
              timeInSec={experiment.wash_time}
              setTime={(newTime: number) => {
                handleCustomValueUpdate('wash_time', newTime);
              }}
              hasError={validationErrors?.wash_time}
              disabled={viewMode}
            />
          </Box>
        </GroupBox>

        <GroupBox mb={2}>
          <Typography className={classes.groupLabel}>Hardness</Typography>
          <Box display="inline-flex" pb={2}>
            <Box>
              <OutlinedInputWithLabel
                label={getInputLabel('Hardness', 'hardness_dh')}
                value={experiment.hardness_dh}
                adornment={boundaryValues['hardness_dh'].field_unit}
                placeholder="0"
                type="number"
                handleChange={handleRatioUpdate}
                error={validationErrors?.hardness_dh}
                errorMessage="Invalid Input"
                disabled={viewMode}
                tooltip="The suggested ratios can be changed"
              />
            </Box>
          </Box>
          <Box display="inline-flex">
            <Box width={'150px'}>
              <OutlinedInputWithLabel
                label={'Hardness Ratio Ca'}
                value={experiment.hardness_ratio_ca}
                type="number"
                handleChange={handleValueUpdate('hardness_ratio_ca')}
                error={validationErrors?.hardness_ratio_ca}
                errorMessage="Invalid Input"
                disabled={viewMode}
              />
            </Box>
            <Box pl={2} width={'150px'}>
              <OutlinedInputWithLabel
                label={'Hardness Ratio Mg'}
                value={experiment.hardness_ratio_mg}
                type="number"
                handleChange={handleValueUpdate('hardness_ratio_mg')}
                error={validationErrors?.hardness_ratio_mg}
                errorMessage="Invalid Input"
                disabled={viewMode}
              />
            </Box>
            <Box pl={2} width={'150px'}>
              <OutlinedInputWithLabel
                label={'Hardness Ratio HCO₃'}
                value={experiment.hardness_ratio_hco3}
                type="number"
                handleChange={handleValueUpdate('hardness_ratio_hco3')}
                error={validationErrors?.hardness_ratio_hco3}
                errorMessage="Invalid Input"
                disabled={viewMode}
              />
            </Box>
          </Box>
        </GroupBox>
      </Box>

      <Box className={classes.advancedWrapper} pt={1} pb={2}>
        <Box className={classes.advancedToggle} onClick={() => setShowAdvanced(!showAdvanced)} pb={2}>
          <Box display="flex" alignItems="center">
            Advanced {showAdvanced ? <DropUp /> : <DropDown />}
          </Box>
        </Box>
        {showAdvanced && (
          <>
            <GroupBox mb={2}>
              <Typography className={classes.groupLabel}>Wash Volume and Speed</Typography>
              <Box display="inline-flex" pb={2}>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    adornment={boundaryValues['wash_volume'].field_unit}
                    label={getInputLabel('Wash vol.', 'wash_volume')}
                    value={experiment.wash_volume}
                    type="number"
                    handleChange={handleValueUpdate('wash_volume')}
                    placeholder="1000"
                    error={validationErrors?.wash_volume}
                    errorMessage="Invalid Input"
                    disabled={viewMode}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    adornment={boundaryValues['wash_agitation_speed'].field_unit}
                    label={getInputLabel('Speed', 'wash_agitation_speed')}
                    value={experiment.wash_agitation_speed}
                    type="number"
                    placeholder="10"
                    handleChange={handleValueUpdate('wash_agitation_speed')}
                    error={validationErrors?.wash_agitation_speed}
                    errorMessage="Invalid Input"
                    disabled={viewMode}
                  />
                </Box>
              </Box>
            </GroupBox>
            <GroupBox mb={2}>
              <Typography className={classes.groupLabel}>Soaking Time</Typography>
              <Box display="inline-flex" pb={3}>
                <TimeField
                  label={getInputLabel('Soaking time', 'soaking_time')}
                  timeInSec={experiment.soaking_time}
                  setTime={(newTime: number) => {
                    handleCustomValueUpdate('soaking_time', newTime);
                  }}
                  hasError={validationErrors?.soaking_time}
                  disabled={viewMode}
                />
              </Box>
            </GroupBox>

            <GroupBox mb={2}>
              <Typography className={classes.groupLabel}>Titration</Typography>
              <Box display="flex" flexDirection="column">
                <Box display="inline-flex">
                  <Box display="flex" pr={1} alignItems="center">
                    pH Adjustment
                  </Box>
                  <ToggleSwitch
                    small
                    labelPosition="left"
                    onChange={() => {
                      handleCustomValueUpdate('titration', !experiment.titration);
                    }}
                    checked={experiment.titration}
                    disabled={viewMode}
                  />
                  {experiment.titration && (
                    <Box pl={3} display="flex">
                      <Typography className={classes.inputLabel} style={{ margin: 'auto', paddingRight: 8 }}>
                        Target pH:{' '}
                        <TooltipComponent title="pH value will not be sent to titration system automatically" />
                      </Typography>
                      <OutlinedInputWithLabel
                        value={experiment.titration ? experiment.target_ph : undefined}
                        placeholder={'Target pH...'}
                        type="number"
                        handleChange={handleValueUpdate('target_ph')}
                        disabled={viewMode}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </GroupBox>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ExperimentForm;
