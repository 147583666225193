import React, { ReactElement } from 'react';
import { Modal, Box, Card, makeStyles } from '@material-ui/core';
import { Button, Close } from '@novozymes-digital/components';

const useStyles = makeStyles({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '1.5rem',
    width: '100%',
    maxWidth: 600,
    borderRadius: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  modalActions: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeModalButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  action: () => void;
  message: string | ReactElement;
}

const ConfirmationModal = (props: ConfirmationModalProps): ReactElement => {
  const classes = useStyles();
  const { open, onClose, action, message } = props;

  return (
    <Modal open={open} onClose={onClose} className={classes.modalRoot}>
      <Card className={classes.modalBody}>
        <Box display="flex" justifyContent="center">
          <Box width="24px" />
          <Box fontSize="1rem" margin="3rem 0">
            {message}
          </Box>
          <Close className={classes.closeModalButton} onClick={onClose} />
        </Box>
        <Box className={classes.modalActions}>
          <Button onClick={action} small>
            Confirm
          </Button>
          <Button small type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default ConfirmationModal;
