import { FieldBoundary } from '../../services/getBoundaryValues';
import { Experiment } from '../../utils/experimentUtils';

export function validateInputValue(
  boundaryValues: Record<string, FieldBoundary>,
  fieldName: string,
  inputValue: number | string
): boolean {
  if (Object.keys(boundaryValues).length === 0) {
    return false;
  } else {
    const res = boundaryValues[fieldName];
    if (res && (inputValue < res.field_min_value || inputValue > res.field_max_value)) {
      return true;
    }
    return false;
  }
}

export function validateDetergentBeaker(
  boundaryValues: Record<string, FieldBoundary>,
  fieldName: string,
  inputValue: number | string
): boolean {
  if (Object.keys(boundaryValues).length === 0) {
    return false;
  } else {
    const res = boundaryValues[fieldName];
    if (res && inputValue === '0') {
      return false;
    }
    if (res && (inputValue < res.field_min_value || inputValue > res.field_max_value)) {
      return true;
    }
    return false;
  }
}

export function validateEln(value: string): boolean {
  if (!value || /^(eln|ELN)\d{8}$/.test(value)) {
    return false;
  }
  return true;
}

export const RINSE_BATCH_TYPE_CONTINUOUS = 'continuous';
export const RINSE_BATCH_TYPE_BATCH = 'batch';

export const validateExperiment = (
  experiment: Experiment,
  boundaryValues: Record<string, FieldBoundary>
): Record<string, boolean> => {
  return {
    eln: validateEln(experiment.eln),
    detergent_dose_per_beaker:
      experiment.detergent_dosing === 'fixed_dose' &&
      validateDetergentBeaker(boundaryValues, 'detergent_dose_per_beaker', experiment.detergent_dose_per_beaker),
    number_of_washes:
      experiment.detergent_dosing === 'fixed_dose' &&
      validateInputValue(boundaryValues, 'number_of_washes', experiment.number_of_washes),
    wash_water_temperature: validateInputValue(
      boundaryValues,
      'wash_water_temperature',
      experiment.wash_water_temperature
    ),
    wash_time: validateInputValue(boundaryValues, 'wash_time', experiment.wash_time),
    wash_volume: validateInputValue(boundaryValues, 'wash_volume', experiment.wash_volume),
    wash_agitation_speed: validateInputValue(boundaryValues, 'wash_agitation_speed', experiment.wash_agitation_speed),
    soaking_time: validateInputValue(boundaryValues, 'soaking_time', experiment.soaking_time),
    hardness_dh: validateInputValue(boundaryValues, 'hardness_dh', experiment.hardness_dh),
  };
};
