import { createTheme } from '@material-ui/core/styles';
import { NovozymesTheme } from '@novozymes-digital/components';

const noTheme = createTheme({
  ...NovozymesTheme,
  typography: {
    ...NovozymesTheme.typography,
    h1: {
      fontWeight: 600,
      fontSize: '56px',
      lineHeight: 1.14,
      color: '#fff',
    },
    h2: {
      opacity: 0.8,
      fontSize: '2rem',
      lineHeight: 1.25,
      color: '#000',
      margin: '2.5rem 0',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 600,
    },
  },
});

export default noTheme;
