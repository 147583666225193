/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiUtils from '../services/apiUtils';
import moment from 'moment';
import {
  BallastDesignType,
  BallastMetaType,
  ConfidentialityEnum,
  EnzymeDesignType,
  EnzymeMetaType,
  IngredientCategoryEnum,
  MatchingType,
  MeasurementInstrumentsType,
  MeasurementMethodsType,
  RunParametersType,
  SwatchDesignType,
  SwatchMetaType,
} from '../services/apiTypes';
import { AxiosResponse } from 'axios';

export type WashKeys =
  | 'order'
  | 'component_1_mass'
  | 'component_2_mass'
  | 'component_3_mass'
  | 'component_4_mass'
  | 'component_5_mass'
  | 'component_6_mass'
  | 'component_7_mass'
  | 'component_8_mass'
  | 'component_9_mass'
  | 'component_10_mass'
  | 'powder_detergent_mass'
  | 'id'
  | 'wash_order_id'
  | 'experiment_id';

export type Wash = Record<WashKeys, string>;

export type MatchKeys = 'batch_id' | 'component' | 'experiment_id' | 'file_column_name' | 'id';

export interface Experiment {
  id: number | null;
  name: string;
  created_by: string;
  valid_from: string;
  valid_till?: string;
  eln: string;
  enzyme_plate_1_addition: boolean;
  enzyme_plate_2_addition: boolean;
  experiment_state: string;
  beaker_jacket_temperature: number;
  batch_id: string;
  component_name: string;
  wash_water_temperature: number;
  wash_volume: string;
  wash_agitation_speed: number;
  wash_time: number;
  soaking_time: number;
  stain_type_number: number;
  detergent_number: number;
  detergent_dosing: string;
  detergent_dose_per_beaker: number;
  number_of_washes: number;
  washes: Array<Wash>;
  matching: MatchingType[];
  access_level?: string;
  modified_by?: string;
  last_modified?: string;
  is_batch: boolean;
  template?: string;
  hardness_dh: number;
  hardness_ratio_ca: number;
  hardness_ratio_hco3: number;
  hardness_ratio_mg: number;
  titration: boolean;
  target_ph: number;
  groups?: string[];
  confidentiality?: ConfidentialityEnum;
  release_date?: string;
  dilution_factor: number;
  detergent_file_name?: string;
  stain_file_name?: string;
  ingredient_category?: IngredientCategoryEnum;
  ingredient_type?: string;
  project_id: string;
  target_customer: string;
  region: string;
  scientist: string[];
  technician: string[];
  ballast_type_number: number;
  ballast_file_name?: string;
  swatch_design: SwatchDesignType[];
  ballast_design: BallastDesignType[];
  ballast_metadata: BallastMetaType[];
  swatch_metadata: SwatchMetaType[];
  enzyme_design_1: EnzymeDesignType[];
  enzyme_design_2: EnzymeDesignType[];
  enzyme_metadata_1: EnzymeMetaType[];
  enzyme_metadata_2: EnzymeMetaType[];
}

export interface HardnessParametersType {
  [key: number]: {
    hardness_dh: number;
    hardness_ratio_ca: number;
    hardness_ratio_hco3: number;
    hardness_ratio_mg: number;
  };
}

export interface UserExperimentResponse {
  data: Experiment[];
}

export const saveExperiment = (experiment: Experiment) => ApiUtils.post('/save_experiment', experiment);

export interface CreateExperiment {
  name: string;
  created_by: string;
  valid_from: string;
  eln: string;
  project_id: string;
  target_customer: string;
  region: string;
  scientist: string[];
  technician: string[];
}

export const createExperiment = (experiment: CreateExperiment) => ApiUtils.post('/create_experiment', experiment);

export const getExperiment = (id: string) => ApiUtils.post('/get_id_experiment', { id });

export const getExperimentsByUser = (username: string) => ApiUtils.post('/get_user_experiment', { username });

export const getArchivedExperimentsByUser = (username: string) => ApiUtils.post('/get_user_deleted', { username });

export const deleteExperiment = (id: number) => ApiUtils.delete('/delete_experiment', { id });

export const hardDeleteExperiment = (id: number) => ApiUtils.delete('/hard_delete_experiment', { id });

export const restoreExperiment = (id: number) => ApiUtils.post('/restore_experiment', { id });

export const getValidExperimentsByUser = (username: string) => {
  return getExperimentsByUser(username).then((res: UserExperimentResponse) => {
    const validExperiments = res.data.filter(
      (experiment: Experiment) =>
        experiment.valid_till === null || moment(experiment.valid_till).unix() > moment().unix()
    );
    return validExperiments;
  });
};

export const getFullExperiment = (id: string) => ApiUtils.post('/get_full_experiment', { id });

interface DuplicateExperiment {
  id: number | null;
  created_by: string;
  name: string;
  eln: string;
}

export const duplicateExperiment = (experiment: DuplicateExperiment) =>
  ApiUtils.post('/duplicate_experiment', experiment);

export const sendToAtom = (id: string) => ApiUtils.post('/send_to_atom', { id });

export const unSendToAtom = (id: string) => ApiUtils.post('/cancel_experiment_atom', { id });

export const getHardnessParameters = () => ApiUtils.get('/hardness_parameters');

/* Swatch */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSwatchMetadata = (experiment_id: number): Promise<AxiosResponse<SwatchMetaType[], any>> =>
  ApiUtils.post('/get_swatch_metadata', { id: experiment_id });

export const addSwatchMetadata = (experiment_id: number, swatch_metadata: SwatchMetaType[]) =>
  ApiUtils.post('/add_swatch_metadata', {
    experiment_id,
    swatch_metadata,
  });

export const addSwatchDesign = (experiment_id: number, swatch_design: SwatchDesignType[]) =>
  ApiUtils.post('/add_swatch_design', { experiment_id, swatch_design });

/* Ballast */
export const getBallastMetadata = (experiment_id: number): Promise<AxiosResponse<BallastMetaType[], unknown>> =>
  ApiUtils.post('/get_ballast_metadata', { id: experiment_id });

export const addBallastMetadata = (
  experiment_id: number,
  ballast_metadata: BallastMetaType[],
  total_wash_load: number
) =>
  ApiUtils.post('/add_ballast_metadata', {
    experiment_id,
    ballast_metadata,
    total_wash_load,
  });

export const addBallastDesign = (experiment_id: number, ballast_design: BallastDesignType[]) =>
  ApiUtils.post('/add_ballast_design', { experiment_id, ballast_design });

export const getMeasurementInstruments = (
  method: string
): Promise<AxiosResponse<MeasurementInstrumentsType[], unknown>> =>
  ApiUtils.get(`/measurement_equipments?method=${method}`);

export const getMeasurementMethods = (): Promise<AxiosResponse<MeasurementMethodsType[], unknown>> =>
  ApiUtils.get('/measurement_methods');

export const getRunParameters = (id: string): Promise<AxiosResponse<RunParametersType[], unknown>> =>
  ApiUtils.post('/get_run_parameters', { id });

/* Enzymes */
export const getEnzymeMetadata = (
  experiment_id: number,
  enzyme_group: number
): Promise<AxiosResponse<EnzymeMetaType[], unknown>> =>
  ApiUtils.post('/get_enzyme_metadata', { experiment_id, enzyme_group });

export const addEnzymeMetadata = (
  experiment_id: number,
  enzyme_group: number,
  enzyme_design?: EnzymeDesignType[],
  enzyme_file?: string,
  enzyme_metadata?: EnzymeMetaType[]
) =>
  ApiUtils.post('/add_enzyme_design_metadata', {
    experiment_id,
    enzyme_group,
    enzyme_metadata,
    enzyme_design,
    enzyme_file,
  });

export const getEnzymeDesign = (
  experiment_id: number,
  enzyme_group: number
): Promise<AxiosResponse<EnzymeDesignType[], unknown>> =>
  ApiUtils.post('/get_enzyme_design', { experiment_id, enzyme_group });
