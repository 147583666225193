import React, { ReactElement } from 'react';
import { Toolbar, makeStyles, Theme, Box } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  processHeaderContainer: {
    paddingLeft: theme.spacing(6),
    backgroundColor: colors.primaryBlue,
    color: colors.white95,
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inlineMenu: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface SubHeaderProps {
  leftSideContent?: ReactNode;
  rightSideContent?: ReactNode;
}

const SubHeader = ({ leftSideContent, rightSideContent }: SubHeaderProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.processHeaderContainer}>
        <Box className={classes.inlineMenu}>{leftSideContent}</Box>
        <Box className={classes.inlineMenu}>{rightSideContent}</Box>
      </Toolbar>
    </>
  );
};

export default SubHeader;
