import React, { ReactElement } from 'react';
import { OutlinedInputWithLabel } from '@novozymes-digital/components';
import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import { BallastMetaType } from '../../../../services/apiTypes';

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    height: 'calc(100% - 7rem)',
    overflowY: 'auto',
    justifyContent: 'space-between',
    margin: '2rem',
  },

  /* New */
  advancedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #E6E6E6',
  },
  advancedToggle: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
  moreFieldsLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  inputWrapper: {
    width: '15rem',
    height: '80px',
    marginRight: theme.spacing(2),
  },
}));

interface BallastFormProps {
  fields: BallastMetaType;
  handleUpdateValue: (key: keyof BallastMetaType, newValue: string | number) => void;
  type: 'fixed' | 'variable';
}

export function BallastForm({ fields, handleUpdateValue, type }: BallastFormProps): ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.modalContent}>
      <Typography className={classes.groupLabel}>Mandatory fields</Typography>
      <Box>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Ballast name'}
              value={fields.ballast_name || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('ballast_name', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Ballast type'}
              value={fields.ballast_type || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('ballast_type', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
      <Box display="inline-flex" pb={2}>
        <Box className={classes.inputWrapper}>
          <OutlinedInputWithLabel
            label={'Ballast batch'}
            value={fields.ballast_batch || ''}
            type="text"
            handleChange={(event) => {
              handleUpdateValue('ballast_batch', event.target.value);
            }}
            disabled={false}
          />
        </Box>
        {type === 'fixed' && (
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label="Ballast Percentage per container"
              value={fields.ballast_percentage_per_container || ''}
              adornment="%"
              type="number"
              handleChange={(event) => {
                handleUpdateValue('ballast_percentage_per_container', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        )}
      </Box>

      <Typography className={classes.groupLabel}>Optional fields</Typography>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Ballast pre treatment description'}
              value={fields.ballast_pre_treatment_description || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('ballast_pre_treatment_description', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Soil ballast_batch_id'}
              value={fields.soil_ballast_batch_id || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('soil_ballast_batch_id', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Soil ballast name'}
              value={fields.soil_ballast_name || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('soil_ballast_name', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Soil ballast pieces pr container'}
              value={fields.soil_ballast_pieces_pr_container || ''}
              type="number"
              handleChange={(event) => {
                handleUpdateValue('soil_ballast_pieces_pr_container', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Soil ballast pre treatment description'}
              value={fields.soil_ballast_pre_treatment_description || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('soil_ballast_pre_treatment_description', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
