import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import DuplicateExperimentModal from './DuplicateExperimentModal';
import { Experiment, deleteExperiment, getValidExperimentsByUser } from '../../utils/experimentUtils';
import { userState } from '../../store/authState';
import CreateImg from '../../assets/create.svg';

import ExperimentsTable from './ExperimentsTable';
import { userExperimentsState } from '../../store/experimentState';
import filterOnProps from '../../utils/filterOnProps';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../DeleteModal';
import { Snackbar, Alert } from '../toaster';
import CreateExperimentDialog from './CreateExperiment';
import FilterTextField from '../FilterTextField';
import getExperimentMenu from './getExperimentMenu';
import CreateTemplateModal from '../Template/CreateTemplateModal';

const useStyles = makeStyles(() => ({
  createExperimentButtonIcon: {
    cursor: 'pointer',
  },
  createExperimentButtonLabel: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
  },
}));

const Experiments = (): ReactElement => {
  const classes = useStyles();
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [userExperiments, setUserExperiments] = useRecoilState<Experiment[]>(userExperimentsState);
  const [experimentForDuplication, setExperimentForDuplication] = useState<undefined | Experiment>(undefined);
  const [experimentForDeletion, setExperimentForDeletion] = useState<undefined | Experiment>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createTemplateModalId, setCreateTemplateModalId] = useState<number | null>(null);

  const [searchString, setSearchString] = useState('');

  const fetchExperiments = useCallback(() => {
    user.attributes.email &&
      getValidExperimentsByUser(user.attributes.email.toLowerCase()).then((res: Experiment[]) => {
        setUserExperiments(res);
      });
  }, [setUserExperiments, user.attributes.email]);

  useEffect(() => {
    fetchExperiments();
  }, [fetchExperiments]);

  const propsToSearch: Array<Partial<keyof Experiment>> = useMemo(
    () => [
      'name',
      'id',
      'eln',
      'experiment_state',
      'created_by',
      'modified_by',
      'template',
      'valid_from',
      'last_modified',
    ],
    []
  );

  const filteredExperiments = useMemo(() => {
    return userExperiments.filter((experiment) => {
      return filterOnProps<Experiment>(propsToSearch, experiment, searchString);
    });
  }, [propsToSearch, userExperiments, searchString]);

  const handleExperimentClick = (experiment: Experiment) => {
    navigate(`/experiment/${experiment.id}`);
  };

  const handleDelete = () => {
    setDeleteModalOpen(false);
    if (experimentForDeletion?.id) {
      deleteExperiment(experimentForDeletion.id)
        .then(() => {
          fetchExperiments();
          setSuccessMessage('Successfully deleted experiment');
        })
        .catch(() => {
          setErrorMessage('Failed to delete experiment');
        });
    } else {
      setErrorMessage('Failed to delete experiment');
    }
  };

  const getMenuItems = (experiment: Experiment) => {
    const onDuplicateClick = () => {
      setExperimentForDuplication(experiment);
    };

    const onCreateTemplateClick = () => {
      setCreateTemplateModalId(experiment.id);
    };
    const onDeleteClick = () => {
      setExperimentForDeletion(experiment);
      setDeleteModalOpen(true);
    };

    const onSharingClick = () => {
      navigate(`/sharing/${experiment.id}`);
    };

    return getExperimentMenu({
      isOwner: experiment.access_level === 'owner',
      onDuplicateClick,
      onCreateTemplateClick,
      onDeleteClick,
      onSharingClick,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={2} pt={1}>
        <FilterTextField onSearchStringUpdate={setSearchString} placeholder="Filter experiments" />
        <Box display="flex" alignItems="center">
          <Box onClick={() => setCreateModalOpen(true)} display="inline-flex">
            <img src={CreateImg} alt="Create experiment icon" className={classes.createExperimentButtonIcon} />
            <Box className={classes.createExperimentButtonLabel}>Create new experiment</Box>
          </Box>
        </Box>
      </Box>
      <ExperimentsTable
        experiments={filteredExperiments}
        onExperimentClick={handleExperimentClick}
        getMenuItems={getMenuItems}
      />
      <DuplicateExperimentModal
        originalExperiment={experimentForDuplication}
        onClose={() => setExperimentForDuplication(undefined)}
      />
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDelete={handleDelete}
        message="Are you sure you want to delete this experiment?"
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage('');
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage('');
          }}
          severity="success"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <CreateExperimentDialog show={createModalOpen} onClose={() => setCreateModalOpen(false)} />
      <CreateTemplateModal
        show={!!createTemplateModalId}
        onClose={() => setCreateTemplateModalId(null)}
        experimentId={createTemplateModalId}
      />
    </Box>
  );
};

export default Experiments;
