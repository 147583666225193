import React, { useEffect, useState, ReactElement } from 'react';
import { makeStyles, TableRow, TableHead, TableContainer, TableBody, Table, Box, IconButton } from '@material-ui/core';
import PopupMenu, { PopupMenuItem } from '../PopupMenu';
import { colors, DropDown, DropUp, More } from '@novozymes-digital/components';

import { Experiment } from '../../utils/experimentUtils';
import moment from 'moment';

import _ from 'underscore';

import { StyledTableCell, StyledTableRow } from '../Table';
import { getUserInitials } from '../../utils/getUserInitials';

interface RowProps {
  experiment: Experiment;
  onRowClick?: () => void;
  menuItems?: PopupMenuItem[];
}

const Row = (props: RowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popupMenuOpen = Boolean(anchorEl);
  const { experiment, onRowClick, menuItems } = props;

  const handlePopupClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  return (
    <>
      <StyledTableRow key={experiment.id}>
        <StyledTableCell component="th" scope="row" onClick={onRowClick}>
          {experiment.name}
        </StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{experiment.id}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{moment(experiment.valid_from).format('YYYY-MM-DD')}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{experiment.eln}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{experiment.experiment_state.toUpperCase()}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{getUserInitials(experiment.created_by)}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{getUserInitials(experiment?.modified_by ?? '')}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{moment(experiment.last_modified).format('YYYY-MM-DD')}</StyledTableCell>
        <StyledTableCell onClick={onRowClick}>{experiment.template}</StyledTableCell>
        <StyledTableCell align="right" nonHover>
          {menuItems && (
            <IconButton color="inherit" component="span" onClick={handlePopupClick}>
              <More />
            </IconButton>
          )}
        </StyledTableCell>
      </StyledTableRow>

      {menuItems && (
        <PopupMenu
          anchorRef={anchorEl}
          popupOpen={popupMenuOpen}
          setAnchorEl={setAnchorEl}
          items={menuItems}
          placement="left"
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  table: {
    boxShadow: 'none',
  },
  header: {
    borderBottom: `2px solid ${colors.dataGrey}`,
  },
  optionsButton: {
    cursor: 'pointer',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'max-content',
  },
});

type SortField =
  | 'valid_from'
  | 'name'
  | 'eln'
  | 'experiment_state'
  | 'created_by'
  | 'id'
  | 'modified_by'
  | 'last_modified';

interface ExperimentsTableProps {
  experiments: Experiment[];
  onExperimentClick?: (experiment: Experiment) => void;
  getMenuItems?: (experiment: Experiment) => PopupMenuItem[];
}

export default function ExperimentsTable({
  experiments,
  onExperimentClick,
  getMenuItems,
}: ExperimentsTableProps): ReactElement {
  const classes = useStyles();
  const [sortField, setSortField] = useState<SortField>('valid_from');
  const [sortOrder, setSortOrder] = useState<string>('DESC');

  const [sortedExperiments, setSortedExperiments] = useState(experiments);

  const handleTableSorting = (field: SortField) => {
    if (sortField === field) {
      sortOrder === 'ASC' ? setSortOrder('DESC') : setSortOrder('ASC');
    } else {
      setSortField(field);
      setSortOrder('DESC');
    }
  };

  const getSortIcon = (field: string) => {
    if (sortField === field) {
      if (sortOrder === 'ASC') {
        return <DropUp />;
      }
      return <DropDown />;
    }
    return <Box width="24px" />;
  };

  useEffect(() => {
    let updatedExperiments = _.sortBy(experiments, (e: Experiment) => {
      if (e[sortField] && typeof e[sortField] === 'string') {
        return e[sortField]?.toString().toLowerCase();
      }
      return e[sortField];
    });

    if (sortOrder === 'DESC') updatedExperiments = updatedExperiments.reverse();

    setSortedExperiments(updatedExperiments);
  }, [sortField, sortOrder, experiments]);

  return (
    <Box>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.header}>
            <TableRow>
              <StyledTableCell onClick={() => handleTableSorting('name')}>
                <Box className={classes.tableHeadContent}>Title {getSortIcon('name')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('id')}>
                <Box className={classes.tableHeadContent}>Id {getSortIcon('id')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('valid_from')}>
                <Box className={classes.tableHeadContent}>Date created {getSortIcon('valid_from')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('eln')}>
                <Box className={classes.tableHeadContent}>Entry ID {getSortIcon('eln')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('experiment_state')}>
                <Box className={classes.tableHeadContent}>State {getSortIcon('experiment_state')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('created_by')}>
                <Box className={classes.tableHeadContent}>Created by {getSortIcon('created_by')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('modified_by')}>
                <Box className={classes.tableHeadContent}>Modified by {getSortIcon('modified_by')}</Box>
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSorting('last_modified')}>
                <Box className={classes.tableHeadContent}>Last modified {getSortIcon('last_modified')}</Box>
              </StyledTableCell>
              <StyledTableCell>Template</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedExperiments &&
              sortedExperiments.map((experiment: Experiment) => (
                <Row
                  key={experiment.id}
                  experiment={experiment}
                  onRowClick={
                    onExperimentClick
                      ? () => {
                          onExperimentClick(experiment);
                        }
                      : undefined
                  }
                  menuItems={getMenuItems && getMenuItems(experiment)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
