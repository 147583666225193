/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@material-ui/core';
import { Button, ToggleSwitch } from '@novozymes-digital/components';
import produce from 'immer';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { read, utils, WorkSheet } from 'xlsx';
import { EnzymeDesignType, EnzymeMetaType } from '../../../services/apiTypes';
import { addEnzymeMetadata, Experiment, getEnzymeMetadata } from '../../../utils/experimentUtils';
import { Template } from '../../../utils/templateUtils';
import DeleteModal from '../../DeleteModal';
import { EnzymeDrawer } from './common/EnzymesDrawer';

export interface ParseCSV {
  [key: string]: number;
}

export type EnzymeGroupType = 'enzyme_plate_1_addition' | 'enzyme_plate_2_addition';

export enum EnzymeGroupEnum {
  enzyme_plate_1_addition = 1,
  enzyme_plate_2_addition = 2,
}

type EnzymeBeakerProps = {
  enzymeGroup: EnzymeGroupType;
  handleCustomValueUpdate: (experimentKey: keyof Experiment, newValue: number | string | boolean | string[]) => void;
  experiment: Experiment | Template;
  viewMode: boolean;
  fileName?: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>> | undefined;
  handleChangeEnzymes?: (enzymes: EnzymeDesignType[], group: EnzymeGroupType) => void;
};

export const EnzymeBeaker = ({
  handleCustomValueUpdate,
  experiment,
  viewMode,
  fileName = '',
  setErrorMessage,
  enzymeGroup,
  handleChangeEnzymes,
}: EnzymeBeakerProps) => {
  const [selectedCsv, setSelectedCsv] = useState<string>(fileName);
  const [parsedCsv, setParsedCsv] = useState<ParseCSV[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [csvColumnOptions, setCsvColumnOptions] = useState<string[]>([]);
  const [enzymeFields, setEnzymeFields] = useState<EnzymeMetaType[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUploadedCsv(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const validateImportedCsv = (results: any[]) => {
    if (results.length > 193) {
      handleRemoveCsv();
      return 'The CSV file exceeds the maximum number of rows (Max=192 rows)';
    }
    return '';
  };

  const convertToArrayOfObjects = (data: any) => {
    const keys = data.shift();
    const mappedData = data.map((row: any) => {
      return keys.reduce(function (obj: any, key: any, i: any) {
        obj[key] = row[i];
        return obj;
      }, {});
    });
    return mappedData;
  };

  const handleUploadedData = (data: any[], file: any) => {
    const filteredResults = data.filter((row: any[]) => row[0] !== '');

    const validationError = validateImportedCsv(filteredResults);
    if (validationError) {
      setErrorMessage?.(validationError);
    } else {
      const parsed = convertToArrayOfObjects(filteredResults);
      setSelectedCsv(file.name);
      setParsedCsv(parsed);
      const columnOptions = Object.keys(parsed[0]);
      setCsvColumnOptions(columnOptions);
      const enzymes: EnzymeMetaType[] = columnOptions.map((column, index) => ({
        enzyme_name: column,
        enzyme_batch: '',
        enzyme_key: `${index}`,
        enzyme_stock_concentration: 0,
        enzyme_type: '',
      }));
      setEnzymeFields(enzymes);
      setOpenDrawer(true);
    }
  };

  const handleRemoveCsv = () => {
    addEnzymes([], []);
    handleCustomValueUpdate(enzymeGroup, false);
    setSelectedCsv('');
    setParsedCsv([]);
    setCsvColumnOptions([]);
    handleChangeEnzymes?.([], enzymeGroup);
  };

  const handleUploadedCsv = (input: any[]) => {
    const file = input.length ? input[0] : null;
    const splitFileName = file.name.split('.');
    const fileType = splitFileName[splitFileName.length - 1];

    if (fileType === 'xlsx') {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const range = ws['!ref'];
        const last = range?.split(':')[1];
        ws['!ref'] = `B1:${last}`;
        const data = utils.sheet_to_json<WorkSheet>(ws, { defval: 0 });
        if (data.length) {
          const columns = Object.keys(data[0]);
          const rows = data.map(function (obj: WorkSheet) {
            return Object.keys(obj).map(function (key) {
              return obj[key];
            });
          });
          const formattedData = [columns, ...rows];
          handleUploadedData(formattedData, file);
        } else {
          setErrorMessage?.("The uploaded file doesn't contain any data");
          handleRemoveCsv();
        }
      };
      reader.readAsBinaryString(file);
    } else if (fileType === 'csv') {
      Papa.parse(file, {
        complete: (results: any) => {
          if (results.data.length && results.data[0][0] !== '' && results.data[1][0] !== '') {
            const filteredData = results.data.map((row: any[]) => row.slice(1));
            handleUploadedData(filteredData, file);
          } else {
            setErrorMessage?.("The uploaded file doesn't contain any data");
            handleRemoveCsv();
          }
        },
      });
    } else {
      setErrorMessage?.('The file format is not supported, only CSV and XLSX files are supported');
    }
  };

  const handleUpdateValue = useCallback(
    (key: keyof EnzymeMetaType, newValue: string | number | string[], enzymeIdx: number) => {
      const nextState = produce(enzymeFields, (draftState) => {
        if (draftState[enzymeIdx]) {
          draftState[enzymeIdx] = { ...draftState[enzymeIdx], [key]: newValue };
        }
      });
      setEnzymeFields(nextState);
    },
    [enzymeFields]
  );

  const fetchEnzymes = useCallback(async () => {
    if (experiment.id) {
      const res = await getEnzymeMetadata(experiment.id, EnzymeGroupEnum[enzymeGroup]);

      if (res.status === 200 && res.data.length > 0) {
        setEnzymeFields(res.data);
      }
    }
  }, [enzymeGroup, experiment.id]);

  const addEnzymes = useCallback(
    async (enzyme_metadata: EnzymeMetaType[], enzyme_design: EnzymeDesignType[]) => {
      if (experiment.id) {
        try {
          await addEnzymeMetadata(
            experiment.id,
            EnzymeGroupEnum[enzymeGroup],
            enzyme_design,
            selectedCsv,
            enzyme_metadata
          );
        } catch (error) {
          setErrorMessage?.('Something went wrong');
          throw error;
        }
      }
    },
    [enzymeGroup, experiment.id, selectedCsv, setErrorMessage]
  );

  const transformDesign = useCallback((design: EnzymeDesignType[], metaData: EnzymeMetaType[]) => {
    return design.map((enzyme) => ({
      ...enzyme,
      wash_design: enzyme.wash_design.map((design) => {
        const enzyme_name =
          metaData.find((data) => data.enzyme_key === design.enzyme_key)?.enzyme_name ?? design.enzyme_key;
        return { ...design, enzyme_name };
      }),
    }));
  }, []);

  const handleSaveData = useCallback(
    (metaData: EnzymeMetaType[]) => {
      let enzymeDesign: EnzymeDesignType[] = parsedCsv.map((item: ParseCSV, index: number) => {
        return {
          wash_order_id: index + 1,
          wash_design: csvColumnOptions.map((key, index) => {
            const enzyme_key = `${index}`;
            const enzyme_name = metaData.find((data) => data.enzyme_key === enzyme_key)?.enzyme_name ?? enzyme_key;
            const enzyme_concentration = item[key];
            return {
              enzyme_key,
              enzyme_name,
              enzyme_concentration,
            };
          }),
        };
      });

      if (enzymeDesign.length === 0) {
        if (enzymeGroup === 'enzyme_plate_1_addition') {
          enzymeDesign = transformDesign(experiment.enzyme_design_1, metaData);
        }
        if (enzymeGroup === 'enzyme_plate_2_addition') {
          enzymeDesign = transformDesign(experiment.enzyme_design_2, metaData);
        }
      }

      try {
        handleCustomValueUpdate(enzymeGroup, true);
        addEnzymes(metaData, enzymeDesign);
        handleChangeEnzymes?.(enzymeDesign, enzymeGroup);
      } catch (error) {
        handleCustomValueUpdate(enzymeGroup, false);
      }
    },
    [
      parsedCsv,
      csvColumnOptions,
      enzymeGroup,
      transformDesign,
      experiment.enzyme_design_1,
      experiment.enzyme_design_2,
      addEnzymes,
      handleChangeEnzymes,
      handleCustomValueUpdate,
    ]
  );

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box {...getRootProps()} pr={2}>
          <ToggleSwitch
            label={enzymeGroup === 'enzyme_plate_1_addition' ? 'ENZ 1' : 'ENZ 2'}
            small
            labelPosition="left"
            onChange={() => {
              const checked = !experiment[enzymeGroup];
              if (checked) {
                open();
              } else {
                setDeleteModalOpen(true);
              }
            }}
            checked={experiment[enzymeGroup]}
            disabled={viewMode}
          />
          <input {...getInputProps()} />
        </Box>
        {experiment[enzymeGroup] && (
          <Button
            small
            onClick={() => {
              fetchEnzymes();
              setOpenDrawer(true);
            }}
          >
            Metadata
          </Button>
        )}
      </Box>
      {experiment[enzymeGroup] && selectedCsv && (
        <Box fontSize="0.9rem" mb={2} display="flex" alignItems="center">
          {selectedCsv}
        </Box>
      )}

      <EnzymeDrawer
        isOpen={openDrawer}
        handleCloseModal={() => setOpenDrawer(false)}
        handleSaveData={handleSaveData}
        enzymesFields={enzymeFields}
        handleUpdateValue={handleUpdateValue}
        viewMode={viewMode}
      />

      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDelete={() => {
          handleRemoveCsv();
          setDeleteModalOpen(false);
        }}
        message="Are you sure you want to delete this Enzyme/beaker?"
      />
    </Box>
  );
};

export default EnzymeBeaker;
