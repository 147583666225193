import React, { ReactElement } from 'react';
import { makeStyles, Box } from '@material-ui/core';

import Tabs from '../components/layout/Tabs';
import TemplatesTable from '../components/Template/TemplatesTable';

import { useRecoilState } from 'recoil';
import { tabState } from '../store/tabState';
import { useMemo } from 'react';
import Experiments from '../components/Experiment/Experiments';
import DeletedExperimentsList from '../components/Experiment/DeletedExperimentsList';
import Page from '../components/layout/Page';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    padding: '4.125rem 5rem',
  },
}));

export default function ScrollableTabsButtonAuto(): ReactElement {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useRecoilState(tabState);

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case 'experiments':
        return <Experiments />;
      case 'templates':
        return <TemplatesTable />;
      case 'deleted':
        return <DeletedExperimentsList />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <Page>
      <Box className={classes.root}>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        {tabContent}
      </Box>
    </Page>
  );
}
