import { PopupMenuItem } from '../PopupMenu';

interface GetDeletedExperimentMenuInput {
  isOwner: boolean;
  onDuplicateClick: () => void;
  onRestoreClick: () => void;
}

const getDeletedExperimentMenu = ({
  isOwner,
  onDuplicateClick,
  onRestoreClick,
}: GetDeletedExperimentMenuInput): Array<PopupMenuItem> => {
  const popupItems = [
    {
      name: 'Duplicate',
      onClick: onDuplicateClick,
    },
  ];

  if (isOwner)
    popupItems.push({
      name: 'Restore',
      onClick: onRestoreClick,
    });

  return popupItems;
};

export default getDeletedExperimentMenu;
