/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from 'react';
import { makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Grid, TableHeaderRow, TableFixedColumns, VirtualTable, Table } from '@devexpress/dx-react-grid-material-ui';
import { colors } from '@novozymes-digital/components';
import { addBallastDesign } from '../../../../utils/experimentUtils';
import { BallastMapping } from './VariableBallast';
import { BallastDesignType } from '../../../../services/apiTypes';
import produce from 'immer';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

const isColumnEditable = (column: string): boolean => {
  return column === 'order' ? false : true;
};

const Cell = (props: any): ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<number | undefined>(undefined);

  const { value, updateCsv, style, column, tableRow, viewMode } = props;

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleInputChange = (event: any) => {
    setCurrentValue(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE && currentValue !== value) {
      setEditMode(false);
      updateCsv(currentValue, column.name, tableRow.rowId);
      setCurrentValue(value);
    } else if (event.keyCode === ESC_KEY_CODE) {
      setEditMode(false);
      setCurrentValue(value);
    }
  };

  return (
    <Table.Cell
      {...props}
      style={{
        borderRight: `1px solid ${colors.dataGrey}`,
        ...style,
      }}
      onClick={() => setEditMode(true)}
    >
      {editMode && isColumnEditable(column.name) && !viewMode ? (
        <>
          <TextField
            value={currentValue}
            onChange={handleInputChange}
            type="number"
            inputProps={{
              style: { fontSize: '0.75rem' },
            }}
            onKeyDown={handleKeyPress}
          />
        </>
      ) : (
        <span>{currentValue}</span>
      )}
    </Table.Cell>
  );
};

const HeaderCell = ({ column, style, ...restProps }: any): ReactElement => (
  <Table.Cell
    {...restProps}
    style={{
      borderRight: `1px solid ${colors.dataGrey}`,
      fontWeight: 'bold',
      ...style,
    }}
  >
    {column.title === 'Powder detergent mass' ? 'Powder detergent' : column.title}
  </Table.Cell>
);

interface BallastTableProps {
  experimentId?: number;
  viewMode?: boolean;
  handleChangeBallasts?: (ballasts: BallastDesignType[]) => void;
  ballastData: BallastDesignType[];
}

interface Column {
  name: string;
  title: string;
}

interface TableColumnExtensions {
  columnName: string;
  width: number | string;
}

const useStyles = makeStyles(() => ({
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  paper: {
    padding: '24px',
    marginTop: '12px',
    borderRadius: '8px',
  },
}));

const BallastTable = (props: BallastTableProps): ReactElement => {
  const classes = useStyles();
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [tableColumnExtensions] = useState<TableColumnExtensions[]>([{ columnName: 'order', width: 100 }]);
  const { experimentId, viewMode, ballastData, handleChangeBallasts } = props;

  useEffect(() => {
    if (ballastData?.length > 0) {
      const rows = ballastData.map((row, idx) => {
        const transformedRow: BallastMapping = {
          order: idx + 1,
        };

        for (let index = 0; index < row.wash_design.length; index++) {
          const washRow = row.wash_design[index];
          transformedRow[washRow.ballast_name] = washRow.ballast_percentage_per_container;
        }
        return transformedRow;
      });
      setRows(rows);

      const mappedColumns = Object.keys(rows[0]).map((column) => ({
        name: column,
        title: getFormattedColumn(column),
      }));
      setColumns(mappedColumns);
    }
  }, [ballastData]);

  const getFormattedColumn = (column: any) => {
    const formatted = column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ');
    const file_column_name = column;
    if (formatted.includes('Order')) {
      return formatted;
    }

    return file_column_name;
  };

  const updateCsv = (updatedValue: number, column: string, row: number) => {
    const nextState = produce(ballastData, (draftState) => {
      const washIdx = draftState[row].wash_design.findIndex((wash) => wash.ballast_name === column);
      draftState[row].wash_design[washIdx].ballast_percentage_per_container = updatedValue;
    });
    handleChangeBallasts?.(nextState);

    if (experimentId) {
      addBallastDesign(experimentId, nextState);
    }
  };

  if (ballastData?.length === 0 || !ballastData) {
    return <></>;
  }

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.groupLabel}>Ballast Table</Typography>
      <Grid rows={rows} columns={columns}>
        <VirtualTable
          columnExtensions={tableColumnExtensions}
          cellComponent={(props: any) => <Cell {...props} updateCsv={updateCsv} viewMode={viewMode} />}
        />
        <TableHeaderRow cellComponent={HeaderCell} />
        <TableFixedColumns leftColumns={['order']} />
      </Grid>
      <Typography className={classes.groupLabel}>Number of ballasts for beaker</Typography>
    </Paper>
  );
};

export default BallastTable;
