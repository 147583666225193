import { Box, makeStyles, Typography } from '@material-ui/core';
import { Button, OutlinedInputWithLabel, SelectWithLabel } from '@novozymes-digital/components';
import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { SelectOption } from '../../utils/commonUtils';
import { getUsers } from '../../utils/getUser';
import { validateEln } from '../Experiment/ExperimentValueValidation';

export type ExperimentInitialFields = {
  name: string;
  eln: string;
  project_id: string;
  target_customer: string;
  region: string;
  scientist: string[];
  technician: string[];
};

type CreateExperimentFormProps = {
  fields: ExperimentInitialFields;
  updateField: (key: keyof ExperimentInitialFields, value: string | string[]) => void;
  onClose(): void;
  handleExperimentSubmit: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    marginBottom: '16px',
  },
}));

export const CreateExperimentForm = ({
  fields,
  updateField,
  onClose,
  handleExperimentSubmit,
}: CreateExperimentFormProps): ReactElement => {
  const classes = useStyles();
  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUsers();

      if (response.status === 200) {
        const users = response.data.users.map((user) => ({ label: user, key: user, value: user }));
        setUserOptions(users);
      }
    };

    fetchUsers();
  }, []);

  const isDisable = useMemo(
    () =>
      !fields.name.length ||
      validateEln(fields.eln) ||
      !fields.eln.length ||
      !fields.project_id.length ||
      !fields.region.length ||
      !fields.scientist.length ||
      !fields.technician.length ||
      !fields.target_customer.length,
    [fields]
  );

  return (
    <Box className={classes.container}>
      <Typography className={classes.label}>You need to fill out all of the data</Typography>
      <OutlinedInputWithLabel
        type="text"
        key="name"
        autoFocus
        label="Enter experiment title"
        tooltip="This field should be used for a descriptive title of the experiment. There will be another field for the Entry ID number."
        value={fields.name}
        handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
          const { value } = event.target;
          updateField('name', value);
        }}
      />

      <Box pt={3}>
        <OutlinedInputWithLabel
          label="Entry ID"
          placeholder="Entry ID"
          tooltip="Entry ID in format: ELNyyxxxx. Make sure to create an Entry before starting the Proton flow. These are available using Benchling."
          value={fields.eln}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            updateField('eln', value);
          }}
          error={validateEln(fields.eln)}
          errorMessage="Invalid Entry ID format"
        />
      </Box>

      <Box pt={3}>
        <OutlinedInputWithLabel
          label="Project ID"
          value={fields.project_id}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            updateField('project_id', value);
          }}
        />
      </Box>

      <Box pt={3}>
        <OutlinedInputWithLabel
          label="Target Customer"
          value={fields.target_customer}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            updateField('target_customer', value);
          }}
        />
      </Box>

      <Box pt={3}>
        <OutlinedInputWithLabel
          label="Region"
          value={fields.region}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            updateField('region', value);
          }}
        />
      </Box>

      <Box pt={3}>
        <SelectWithLabel
          value={fields.scientist}
          multiple
          label="Scientists"
          options={userOptions}
          placeholder="Please select..."
          handleChange={(e) => {
            updateField('scientist', e.target.value as string[]);
          }}
        />
      </Box>

      <Box pt={3}>
        <SelectWithLabel
          value={fields.technician}
          label="Technicians"
          multiple
          options={userOptions}
          placeholder="Please select..."
          handleChange={(e) => {
            updateField('technician', e.target.value as string[]);
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Box>
          <Button small type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Box pl={1}>
          <Button type="primary" small onClick={handleExperimentSubmit} disabled={isDisable}>
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
