import { withStyles, createStyles, TableRow, TableCell } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';

export const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      fontWeight: 'bold',
      fontSize: '0.875rem',
      cursor: 'pointer',
    },
    body: {
      fontSize: '0.875rem',
      border: 'none',
      cursor: (props: { nonHover?: boolean; onClick?: () => void }) =>
        props.nonHover || !props.onClick ? 'default' : 'pointer',
    },
  })
)(TableCell);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      height: '4.5rem',
      '&:nth-of-type(even)': {
        backgroundColor: colors.bg2,
      },
    },
  })
)(TableRow);
